import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material';
import { ISelectedOptions, IVerslagItemGroup, IVerslagItemGroups, IVerslagOption, IVerslagOptionTree } from '../interfaces';
import VerslagItem from './item2';
import VerslagOptionToggleGroup from './item2';


const HeaderItemLabel = styled(Typography)(() => ({
    gridRow:1,
    margin: "5px 0 5px 5px",
    padding: "5px 10px",
    borderRadius: "5px",
    border: "1px solid var(--primary-100)",
    width: "fit-content", 
    background: "var(--neutral-100)"
}))


const HeaderItemStyled = styled('div',{
    name:"HeaderItem",
    slot:"root"
})(({theme}) => ({
    display: "grid",
    gridTemplateRows: "fit-content auto",
    borderBottom: "1px solid black",
}))

interface IHeaderItem {
    //optionTree: IVerslagOptionTree
    itemGroups : IVerslagItemGroups
}

const HeaderItem:React.FC<IHeaderItem> = ({itemGroups}) => {
    const [renderGroups, setRenderGroups] = React.useState<{[key:string]:boolean}>({})

    React.useEffect(() => {
        console.log('Header->ItemGroups', itemGroups)
        var _renderGroups: {[key:string]:boolean} = {}
        itemGroups.keys.forEach((key, index) => {
            _renderGroups[key] = index == 0 ? true : false
        })
        setRenderGroups(_renderGroups)
    }, [itemGroups])

    React.useEffect(() => {
        //console.log("RenderGroups", renderGroups) 
    }, [renderGroups])
    return (
        <HeaderItemStyled>
            {
                Object.entries(renderGroups).map(([key,render], index) => {
                    if (render)return <VerslagItem key={key} setRenderGroups={setRenderGroups} itemGroup={itemGroups.itemGroups[key]}/>                        
                    
                    
                })
            }
        </HeaderItemStyled>
    )
}

/*
const HeaderItem2: React.FC<IHeaderItem> = ({itemGroups}, props) => {
    const [headerOption, setHeaderOption] = React.useState<IVerslagOption>()
    const [itemOption, setItemOption] = React.useState<IVerslagOption>()
    
    React.useEffect(() => {
        
        if (optionTree == undefined) setHeaderOption(undefined)
        else {
            setHeaderOption(optionTree.tree.find(option => option.type == "header"))
        }
        
        
    }, [optionTree])
    
    React.useEffect(() => {
        
    }, [])
    
    React.useEffect(() => {
        if (headerOption != undefined) setItemOption(optionTree.tree.find(option => option.selector == headerOption.options[0]))
    }, [headerOption])
    
    return (
        <HeaderItemStyled >
            <HeaderItemLabel align='left'>{headerOption?.value}</HeaderItemLabel>
            { itemOption  ? 
                <VerslagItem 
                    option={itemOption}
                    optionTree={optionTree}
                    key={itemOption.id}
                /> : ''
               
               
            }
        </HeaderItemStyled>
    )
    
};
*/
export default HeaderItem;