import * as React from 'react'
import {
    Paper, Box
} from '@mui/material'
import Grid from '../../components/Containers/Grid'
import Grid2 from '@mui/material/Unstable_Grid2';
const logo = require('../../assets/logo.jpg')

import { ITarificatie } from './interfaces'

interface IDokterContainer {
    props:string[]
}

const DokterContainer: React.FC<IDokterContainer> = ({props}) => {
    if (props.length == 2){
        return (
                <> 
                        <Grid2 xs={5} style={{padding:'0'}}> {props[0]} </Grid2>
                        <Grid2 xs={5} style={{padding:'0'}}> {props[1]} </Grid2>
                        <Grid2 xs={2} style={{border:'1px solid black', padding:'0'}}>  </Grid2>
                        </>
                    
                
            )
        }
    else{
        return (
            <>
                
                    <Grid2 xs={10} style={{padding:'0'}}> {props[0]} </Grid2>
                    <Grid2 xs={2} style={{border:'1px solid black', padding:'0'}}>  </Grid2>
                
                </>
        )
    }
    
}

const PreviewContainer: React.FC<ITarificatie> = (props:ITarificatie) => {
    const titleCase = (str:string) => {
        return str.split(/[\s-]+/).map((e:string) => {
            return e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()
        }).join(' ')
    }

    const [patientArray, setPatientArray] = React.useState<string[]>([])
    React.useEffect(() => {
        //5307220115|ACKX|ANNIE ELODIE|70293||||HERMANS-LYBAERTSTRAAT 88 BUS 0011|HEIST-AAN-ZEE|22071953|8301|V
        var PA:string[] = props.patient.split('|')
        if (PA.length != 12) {setPatientArray([]);return;}
        PA[2] = titleCase(PA[2])
        PA[7] = titleCase(PA[7])
        setPatientArray(PA)


    }, [props.patient]) 
    return (
        
        <Paper elevation={6}
            style={{
                height:"29.7cm", width:"21cm",
                minWidth:"21cm", minHeight:"29.7cm",
                margin:"auto 0"
            }}
        >
            <Grid sx={{gridTemplateColumns: '1fr 1fr', gridTemplateRows: '7.3cm auto', gridGap:'10px', height:'calc(100vh - 70px)'}}>
                <Box 
                    component="img"
                    alt="AZ ZENO"
                    src={logo}
                    style={{
                        width:'11cm', height:'7.3cm',
                        objectFit:"cover", objectPosition: "-20px -20px"

                    }}
                />
                <Grid sx={{
                        gridTemplateColumns: '3fr 3fr 2fr', gridTemplateRows: '4fr 2fr',
                         gridGap:'10px', fontSize:'15px',
                         fontFamily:'Roboto'
                    }}>
                    <Box
                        style={{
                            border: "1px solid black",
                            margin: "0.3in 0.3in 0 0",
                            gridColumn:'span 3'
                            
                        }}
                    >
                        {
                            patientArray.length == 0 ? '' : 
                                
                                <Grid2 container style={{padding:'10px'}} spacing={1}>
                                    <Grid2 xs={12} > {patientArray[2] + ' ' + patientArray[1]} </Grid2>
                                    <Grid2 xs={12} > {'°' + patientArray[9].slice(0,2) + '-' +patientArray[9].slice(2,4) + '-' +patientArray[9].slice(4) } </Grid2>
                                    <Grid2 xs={12} > {patientArray[7]} </Grid2>
                                    <Grid2 xs={12} > {patientArray[10] + ' ' + patientArray[8]} </Grid2>
                                    <Grid2 xs={12} > {'Patiëntennummer ' + patientArray[0]} </Grid2>
                                </Grid2>
                            
                        }
                        
                    </Box>
                    <Box
                        style={{
                            margin: "0 0.3in 0 0",
                            gridColumn:'span 3'
                            
                        }}
                    >   
                    
                        <Grid2 container style={{padding:'0px', margin:'0'}} spacing={1}>
                            <DokterContainer props={["Dr. MAREELS", "146171 - 095314"]} />
                            <DokterContainer props={["Dr. VAN DYCKE", "136156 - 095351"]} />
                            <DokterContainer props={["Dr. VERVAECKE", "138288 - 095852"]} />
                            <DokterContainer props={["Dr. ASSISTENT Gastroenterologie"]} />
                        </Grid2>
                    </Box>
                </Grid>

                
            </Grid>
        </Paper>
        
    )
}
export default PreviewContainer