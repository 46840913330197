import { FC } from 'react';
import { useFormContext, Controller, FieldError } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';

// 👇 Styled Material UI TextField Component

const CssTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "fontSize"  && prop !== "paddingTop"  && prop !== "paddingBottom" && prop !== "color"
})<FormInputProps>((props) => ({
  'input': {
    ...(props.fontSize && {fontSize: props.fontSize }),
    ...(props.paddingTop && {paddingTop: props.paddingTop }),
    ...(props.paddingBottom && {paddingBottom: props.paddingBottom }),
    ...(props.color && {color: props.color }),
  },
  '& label.Mui-focused': {
    color: '#5e5b5d',
    fontWeight: 400,
  },
  '& .MuiInputBase-input': {
    borderColor: '#c8d0d4',
  },
  '& .MuiInput-underline:after': {
    border: 'none',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#d32f2f',
      },
    },
    '& fieldset': {
      borderColor: '#c8d0d4',
      borderRadius: 0,
    },
    '&:hover fieldset': {
      border: '1px solid #c8d0d4',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #c8d0d4',
    },
  },
}));


type FormInputProps = {
  name: string;
  fontSize?: string
  paddingTop?: string
  paddingBottom?: string
  color?: string
  
} & TextFieldProps;

const FormInput: FC<FormInputProps> = ({ name, ...otherProps }) => {
  
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=''
      render={({ field }) => (
        <CssTextField
          {...field}
          {...otherProps}
          variant='outlined'
          sx={{ mb: '1.5rem' }}
          error={!!errors[name]}
          helperText={errors[name] ? errors[name]?.message as string : ''}
        />
      )}
    />
  );
};

export default FormInput;