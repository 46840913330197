import * as React from 'react'

const LogoSVG = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
    version="1.1"
    viewBox="0 0 356 56.5"
    xmlns="http://www.w3.org/2000/svg"
    >
        <g transform="matrix(.67652 0 0 .67652 0 -1.4883)" className="symbol" >
            <polygon points="78.1 50.6 70.3 35.9 59.8 75.7 48.6 27.5 36.1 85.6 25.8 10.5 14.6 50.6 0 50.7 12 48.5 25.8 2.2 36.4 62.6 48.6 22.2 60 59.3 70.2 30.7 79.3 48.6 89.7 50.4" imageRendering="auto"/>
        </g>
        <g transform="matrix(1.0084 0 0 1.0084 78.839 .8986)" className="text" >
            <path d="m19.2 15.52h6.56l-9.8 24.48h-6l-9.8-24.48h6.52l6.28 17.64zm9.12 0h18.2v5.56h-12.2v3.92h9.8v5.56h-9.8v3.88h12.2v5.56h-18.2zm27.6 5.64v5.84h5.6c1.84 0 2.92-1.08 2.92-2.92s-1.08-2.92-2.92-2.92zm9.08 9.8c1.12 1.52 2.24 3.04 3.4 4.52 1.12 1.48 2.24 3 3.36 4.52h-7.32c-1.44-1.92-2.84-3.84-4.24-5.76-1.4-1.88-2.84-3.8-4.28-5.72v11.48h-6v-24.48h11.6c4.56 0 8.44 3.48 8.44 8.08 0 3.24-2 6.08-4.96 7.36zm25.48-15.44h6.56l-9.8 24.48h-6l-9.8-24.48h6.52l6.28 17.64zm22 19.52h-9l-1.76 4.96h-6.52l9.8-24.48h6l9.8 24.48h-6.56zm-7.04-5.56h5.08l-2.52-7.12zm17.8-13.96h18.2v5.56h-12.2v3.92h9.8v5.56h-9.8v3.88h12.2v5.56h-18.2zm40.84 17.64v6.16c-2.48 0.68-4.96 0.96-7.52 0.96-7.52 0-12.56-5.16-12.56-12.48 0-7.52 5.24-12.48 12.52-12.48 2.52 0 5.16 0.2 7.56 0.96v6.16c-1.96-0.96-5.04-1.32-7.2-1.32-4.88 0-6.88 2-6.88 6.64 0 4.92 2.36 6.72 6.92 6.72 2.04 0 5.36-0.32 7.16-1.32zm3.48-17.64h6v11.48l7.88-11.48h7.44l-8.56 12.24 8.88 12.24h-7.36l-8.28-11.52v11.52h-6zm23.32 0h18.2v5.56h-12.2v3.92h9.8v5.56h-9.8v3.88h12.2v5.56h-18.2zm28.64 21.24c0 2.04-1.4 3.44-3.44 3.44s-3.48-1.36-3.48-3.44c0-2.04 1.44-3.48 3.48-3.48 2.12 0 3.44 1.36 3.44 3.48z"/>
        </g>
        <g transform="matrix(.67652 0 0 .67652 0 -1.4883)" className="ext" >
            <path transform="matrix(1.4906 0 0 1.4906 116.54 3.5283)" d="m234.69 32.204c0-2.3542-1.1614-3.6411-3.547-3.6411-0.65917 0-1.2242 0.09417-1.695 0.2825-0.87889 0.40806-1.4753 1.0045-1.7578 1.9461-0.15695 0.43945-0.21972 0.91028-0.21972 1.4125 0 0.50223 0.0628 0.97306 0.21972 1.4125 0.25111 0.91028 0.91028 1.5695 1.7578 1.9147 0.47084 0.21972 1.0358 0.31389 1.695 0.31389 2.3856 0 3.547-1.287 3.547-3.6411zm0-6.2778v-7.8473h4.7084v14.125c0 4.9909-3.547 8.0042-8.2867 8.0042-4.8339 0-8.3495-3.1389-8.3495-7.9414 0-4.4259 3.2645-8.067 7.7217-8.067 1.6636 0 3.1075 0.47084 4.2061 1.7264zm12.085 4.3945c0.47084 0.15694 1.0044 0.2825 1.5695 0.34528 0.565 0.09417 1.0986 0.12556 1.6008 0.12556 0.62778 0 2.2286-0.12556 2.2286-1.0358 0-0.97306-1.3811-1.0045-2.0717-1.0045-1.3811 0-2.5739 0.31389-3.3272 1.5695zm8.8831 4.8339v4.3945c-1.7578 0.40806-3.5156 0.69056-5.3362 0.69056-4.9909 0-8.6948-2.9192-8.6948-7.9728 0-4.9909 3.5784-8.067 8.3181-8.067 3.2331 0 6.9684 1.5381 6.9684 5.2106 0 3.6411-3.955 4.9909-6.9998 4.9909-1.1614 0-2.3228-0.31389-3.39-0.75334 0.62778 1.7578 2.5111 2.0403 4.1434 2.0403 0.84751 0 1.695-0.03139 2.5425-0.15695 0.78473-0.06278 1.695-0.15694 2.4484-0.37667zm19.022-10.798-6.4348 15.695h-4.7084l-6.4348-15.695h4.677c0.69056 1.7892 1.3811 3.547 2.0717 5.3361 0.65917 1.7892 1.3497 3.547 2.0403 5.3361 0.69056-1.7892 1.3811-3.547 2.0717-5.3361 0.65917-1.7892 1.3497-3.547 2.0403-5.3361z" strokeWidth=".78473"/>
        </g>
    </svg>
)

export default LogoSVG;