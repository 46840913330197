import * as React from 'react'
import {
    Paper, Box, TextField
} from '@mui/material'
import Grid from '../../components/Containers/Grid'
import Grid2 from '@mui/material/Unstable_Grid2';

import { ITarificatie } from './interfaces'

const InputContainer: React.FC<ITarificatie> = (props:ITarificatie) => {
    return (
        <Paper elevation={6}
            style={{
                maxHeight:'100%',     backgroundColor: "var(--neutral-100)",
                padding:'10px 10px'
            }}
        >
            <Grid2 container spacing={2} >
                <Grid2 xs={8} >
                    <TextField 
                        fullWidth
                        label="Patient" value={props.patient}
                        onChange={(event) => {props.setPatient(event.target.value)}}
                        
                    />
                </Grid2>
                <Grid2 xs={4} >
                    <TextField 
                        fullWidth
                        label="Datum" value={props.datumOnderzoek}
                        onChange={(event) => {
                            const input:string = event.target.value
                        
                            //const m = input.match(/(0[1-9]|[12][0-9]|3[01])[-\/](0[1-9]|1[0-2])[-\/](\d{4})/)
                            
                            const m = input.match(/(0[1-9]|[12][0-9]|3[01]|[1-9])[-\/](0[1-9]|1[0-2]|[1-9])[-\/](\d{4})/)
                            if (m){
                                if (m[2].length == 2 && m[1].length == 2){
                                    props.setDatumOnderzoek(`${m[3]}-${m[2]}-${m[1]}`)
                                    
                                }
                                else if (m[2].length == 2 && m[1].length==1){
                                    props.setDatumOnderzoek(`${m[3]}-${m[2]}-0${m[1]}`)
                                }
                                else if (m[2].length == 1 && m[1].length == 2){
                                    props.setDatumOnderzoek(`${m[3]}-0${m[2]}-${m[1]}`)
                                }else{
                                    props.setDatumOnderzoek(`${m[3]}-0${m[2]}-0${m[1]}`)
                                }
                                
                            }
                            else{
                                props.setDatumOnderzoek(event.target.value)
                                
                            }
                            
                        }}
                    />
                </Grid2>
                

                
            </Grid2>
        </Paper>
    )
}
export default InputContainer