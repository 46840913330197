import React, {useEffect, useState, useRef} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import FastAPIClient from '../../client';
import config from '../../config';
import jwtDecode from "jwt-decode";
import * as moment from "moment";
import parseCookie from '../../utils/cookie'
import updateSessionStorage from '../../utils/events'


import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
//import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import HomeIcon from '@mui/icons-material/Home';
import { FormControlUnstyledContext } from '@mui/base';

import { history } from '../../utils/utils';
import Logo from '../Logo';



const client = new FastAPIClient(config);
var isLoggedInListener = null;

const DashboardHeader = ({isLoggedIn, setIsLoggedIn, activeUser, setActiveUser}) => {
  
  const navigate = useNavigate();
  history.navigate = navigate
   
  //const [isLoggedIn, setIsLoggedIn] = useState(false);
  //
  const isLoggedInRef = useRef(isLoggedIn)
  const pages = ['Screening', 'Pancreas', 'Docs', 'Tarificatie', 'Database'];
  const settings = ['Profile', 'Logout'];
  
  // STATE WHICH WE WILL USE TO TOGGLE THE MENU ON HAMBURGER BUTTON PRESS
  const [toggleMenu, setToggleMenu] = useState(false);
  const [avatar, setAvatar] = useState(activeUser.avatar ? activeUser.avatar : '')
  const [isLoading,setIsLoading] = useState(true)
  useEffect(() => {
    client.token().then(response => {
      const tokenString = response.data//JSON.stringify(response.data)
      
      if (tokenString) {
        const decodedAccessToken = jwtDecode(tokenString)
        
        if(moment.unix(decodedAccessToken.exp).toDate() > new Date()){
            setIsLoggedIn(true)
        }
      }
      else{
          document.cookie = "redirecturl="+window.location.pathname
          navigate("/login")
      }
    setIsLoading(false)
   })
  }, [])
  
  
  
    
  const handleLogout = () => {
    client.logout();
    setIsLoggedIn(false)
    navigate('/')
  }

  const handleLogin = () => {
    navigate("/login");
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  
  let loginButton;
  
  if (isLoggedIn) {
    if (activeUser.username == ''){
      client.fetchUser().then( (data) => {
        setActiveUser(data)
    })
    }
    
      
    loginButton = <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} > <Avatar sx={{width:'60px', height:'60px'}} alt={activeUser.username} src={avatar} /></IconButton>;
  } else {
    loginButton = <Button style={{ fontSize: '1.2rem' }} color="inherit" onClick={() => handleLogin()}>Login</Button>;
  }
  
  const updateIsLoggedIn = () => {
    if (isLoggedIn != (sessionStorage.getItem('isLoggedIn') == 'true')){
      setIsLoggedIn(sessionStorage.getItem('isLoggedIn') == 'true')
    }
  }
  useEffect(() => {
    window.addEventListener("updateSessionStorage", updateIsLoggedIn)
    if (isLoggedIn) {
      if (activeUser.username == ''){
        client.fetchUser().then( (data) => {
          setActiveUser(data)
      })}
      
      
      loginButton = <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} > <Avatar sx={{width:'60px', height:'60px'}} alt={activeUser.username} src={avatar} /></IconButton>;;
    } else {
      loginButton = <Button style={{ fontSize: '1.2rem' }} color="inherit" onClick={() => handleLogin()}>Login</Button>;
    }
    return () => {
      window.removeEventListener("updateSessionStorage", updateIsLoggedIn)
    }
  }, [isLoggedIn])

  React.useEffect(() => {
    try {
      setAvatar(require("Assets/avatars/"+activeUser.avatar+".jpg"))
      
    }catch(ex){
      
      setAvatar('')
    }
  }, [activeUser])
  
  /*
  useEffect(() => {
    
  },[isLoggedIn])
  */
    
      const [anchorElNav, setAnchorElNav] = React.useState(null);
      const [anchorElUser, setAnchorElUser] = React.useState(null);
    
      const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
      };
      
    
      const handleCloseNavMenu = (event) => {
        console.log(event)
        setAnchorElNav(null);
      };
    
      const handleCloseUserMenu = () => {
        setAnchorElUser(null);
      };
    /*
    <HomeIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
      <Typography
          variant="h5"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
            marginRight:0
          }}
        >
          Vervaecke
        </Typography>
        <Typography
          variant="h7"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 900,
            letterSpacing: '.0rem',
            color: 'inherit',
            textDecoration: 'none',
            paddingTop:'6px'
          }}
        >
          DEV
        </Typography>
      */
  return (
    <AppBar position="static" style={{height:'64px'}}>
    <Container maxWidth="xxl">
      <Toolbar disableGutters sx={{gap:"25px"}}>
        <Logo width="250px" fill="white" href="/" />
        
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {isLoggedIn ? pages.map((page) => (
			
				<Button
          key={page}
          onClick={handleCloseNavMenu}
          sx={{ my: 2, color: 'white', display: 'block' , paddingTop: '8px', margin: 'auto 0'}}
          href={'/' + page}
				>
				{page}
				</Button>
			
          )) : ''}
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          {isLoading ? "": <Tooltip title="Open settings" >{loginButton}</Tooltip>}
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </Container>
  </AppBar>
  );
}

export default DashboardHeader;