import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
//import { boolean, literal, object, string, TypeOf, in} from 'zod';
import * as zod from "zod";
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from '../FormInput'

type addRecordDialogProps = {
    open: boolean,
    handleClose: Function,
    values:any
    onderzoekTypeSelected:string
    onderzoeken?:string[]
}
var allowedExams : any;

import config from '../../config';
import FastAPIClient from '../../client';
import { ZodString } from 'zod';
import { KeyboardEventHandler } from 'react';
const client = new FastAPIClient(config);





const ScreeningAddRecordDialog :React.FC<addRecordDialogProps> = ({open, handleClose, values, onderzoekTypeSelected}) => {
    const [name, setName] = React.useState('')
    const [dob, setDob] = React.useState('')
    const [ptnr, setPtnr] = React.useState('')
    const [datum, setDatum] = React.useState('')
    const [exam, setExam] = React.useState('')

    

    const recordSchema = zod.object({
        name: zod.string().min(3,'Name is required'),
        dob: zod.string().regex(/\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/, "Not a valid date in format YYYY-MM-DD"),
            
        ptnr: zod.string().min(10, "number too short").max(10, "number too long"),
        datum: zod.string().regex(/\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/, "Not a valid date in format YYYY-MM-DD"),
        exam: zod.enum(allowedExams)
    });

    type IRecord = zod.TypeOf<typeof recordSchema>;
    
    const defaultValues:IRecord = {
        name:'', dob:'', ptnr:'', datum:new Date().toISOString().slice(0, 10), exam:onderzoekTypeSelected
    }
    

    React.useEffect(() => {
        defaultValues.exam = onderzoekTypeSelected
        defaultValues.datum = new Date().toISOString().slice(0, 10)
        methods.reset({...defaultValues})
        
    }, [onderzoekTypeSelected])

    const methods = useForm<IRecord>({
        resolver: zodResolver(recordSchema),
        defaultValues,
        
    });
    
    const onClose = () => {
        clear()
    }
    const clear = () => {
        setName('')
        setDob('')
        setPtnr('')
        setDatum('')
        setExam('')
    }
    const onOk: SubmitHandler<IRecord> = (data:IRecord) => {
        values.name = data.name
        values.dob = data.dob
        values.ptnr = data.ptnr
        values.datum = data.datum
        values.exam = data.exam
        handleClose()
        methods.reset()

    }
    const onCancel = () => {
        methods.reset()
        values.cancel = true
        handleClose()
    }
    const onError = (errors:any, e:any) => {console.log(errors, e)}
    
    const onChange = (event:React.ChangeEvent<HTMLInputElement>) => {
       const target = event.target as HTMLInputElement
       switch (target.name){
            case 'name': 
                setName(target.value)
                break;
            case 'dob':
                setDob(target.value)
                break;
            case 'ptnr':
                setPtnr(target.value)
                break;  
            case 'datum':
                setDatum(target.value)
                break;
            case 'exam':
                setExam(target.value)
                break;
       }
       
    }
    
    
    React.useEffect(() => {
        client.onderzoek_types().then((response:any) => {
            allowedExams = response.data
            
        })
        if ("name" in values ) setName(values.name)
        if ("dob" in values ) setDob(values.dob)
        if ("ptnr" in values ) setPtnr(values.ptnr)
        if ("datum" in values ) setDatum(values.datum)
        if ("exam" in values ) setExam(values.exam)
    }, [])

    
    return (
        <FormProvider {...methods}>
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add Record</DialogTitle>
            <DialogContent style={{paddingTop:"5px"}}>
                <FormInput
                    name="name"
                    label="name"
                    fullWidth autoComplete='off'
                    required
                    style={{marginBlockStart:"5px",marginBlockEnd:"5px"}}
                ></FormInput>
                <FormInput
                    name="dob"
                    label="Geboorte Datum"
                    fullWidth autoComplete='off'
                    style={{marginBlockStart:"5px",marginBlockEnd:"5px"}}
                    
                    //value={dob}
                    onChange={(event) => {
                        const input:string = event.target.value
                        
                        const m = input.match(/(0[1-9]|[12][0-9]|3[01])[-\/](0[1-9]|1[0-2])[-\/](\d{4})/)
                        const n = input.match(/(0[1-9]|[12][0-9]|3[01])[-\/](0[1-9]|1[0-2])[-\/](\d{4})/)
                        if (m){
                            methods.setValue('dob',`${m[3]}-${m[2]}-${m[1]}`)
                            methods.clearErrors('dob')
                        }
                        else{
                            methods.setValue('dob',event.target.value)
                        }
                    }}
                ></FormInput>
                <FormInput
                    name="ptnr"
                    label="Patienten nr."
                    fullWidth autoComplete='off'
                    style={{marginBlockStart:"5px",marginBlockEnd:"5px"}}
                ></FormInput>
                <FormInput
                    name="datum"
                    label="Datum Onderzoek"
                    fullWidth autoComplete='off'
                    style={{marginBlockStart:"5px",marginBlockEnd:"5px"}}
                    onChange={(event) => {
                        const input:string = event.target.value
                        
                        //const m = input.match(/(0[1-9]|[12][0-9]|3[01])[-\/](0[1-9]|1[0-2])[-\/](\d{4})/)
                        
                        const m = input.match(/(0[1-9]|[12][0-9]|3[01]|[1-9])[-\/](0[1-9]|1[0-2]|[1-9])[-\/](\d{4})/)
                        if (m){
                            if (m[2].length == 2 && m[1].length == 2){
                                methods.setValue('datum',`${m[3]}-${m[2]}-${m[1]}`)
                                
                            }
                            else if (m[2].length == 2 && m[1].length==1){
                                methods.setValue('datum',`${m[3]}-${m[2]}-0${m[1]}`)
                            }
                            else if (m[2].length == 1 && m[1].length == 2){
                                methods.setValue('datum',`${m[3]}-0${m[2]}-${m[1]}`)
                            }else{
                                methods.setValue('datum',`${m[3]}-0${m[2]}-0${m[1]}`)
                            }
                            methods.clearErrors('datum')
                        }
                        else{
                            methods.setValue('datum',event.target.value)
                            
                        }
                    }}
                    onKeyDown = {(event:any) => {
                        if (event.which == 13){
                            methods.handleSubmit(onOk)(event)
                        }
                    }}
                ></FormInput>
                <FormInput
                    name="exam"
                    label="Onderzoek"
                    fullWidth autoComplete='off'
                    style={{marginBlockStart:"5px",marginBlockEnd:"5px"}}
                ></FormInput>
                
                
            </DialogContent>
            <DialogActions>
                <Button onClick={methods.handleSubmit(onOk)}>Ok</Button>
                <Button onClick={onCancel}>Cancel</Button>
            </DialogActions>
            
        </Dialog>
        </FormProvider>
    )
}

export default ScreeningAddRecordDialog