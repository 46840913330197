import * as React from 'react'
import { Tab, Box } from '@mui/material';
import { Unstable_Grid2 as Grid2 } from '@mui/material';
import { TabPanel } from '@mui/lab' 

import { ScreeningPanelProps } from './interfaces';
import Grid from '../Containers/Grid';
import ScreeningViewToolbar from './PanelView/ScreeningViewToolbar';
import ScreeningViewContainer from './PanelView/ScreeningViewContainer';

import FastAPIClient from "../../client";
import config from "../../config";

class Client extends FastAPIClient{
    view_overdue(exam_type:string, date:string){
        const form_data = new FormData()
        form_data.append("exam_type", exam_type)
        form_data.append("date", date)
        return this.apiClient.post('/screening/view/overdue', form_data)
    }
    view_upcoming(exam_type:string, start_date:string, end_date:string){
        const form_data = new FormData()
        form_data.append("exam_type", exam_type)
        form_data.append("start_date", start_date)
        form_data.append("end_date", end_date)
        return this.apiClient.post('/screening/view/overdue', form_data)
    }
};

const client = new Client(config);

export interface IRow {
    id: number;
    name: string;
    ptnr: string;
    datum: string;
    termijndate: string;
    termijn: string;
    vetotermijn: string;
}

const ScreeningPanelView: React.FC<ScreeningPanelProps> = ({...props}) => {
    const {
        value, onderzoekTypes, onderzoekTypeSelected, setOnderzoekTypeSelected
    } = props

    const upcomingTerms = ["1 maand", "2 maand", "3 maand", "6 maand", "1 jaar"]
    const [upcomingTerm, setUpcomingTerm] = React.useState<string>("3 maand")
    const [overdueRows, setOverdueRows] = React.useState<IRow[]>([])
    const [upcomingRows, setUpcomingRows] = React.useState<IRow[]>([])

    const ToolbarProps = {
        onderzoekTypes : onderzoekTypes ? onderzoekTypes : [],
        onderzoekTypeSelected : onderzoekTypeSelected ? onderzoekTypeSelected as "" : "",
        setOnderzoekTypeSelected,
        upcomingTerm: upcomingTerm ? upcomingTerm as "" : "",
        upcomingTerms, setUpcomingTerm
    }
    React.useEffect(() => {
        if (onderzoekTypeSelected == "" || onderzoekTypeSelected == undefined) return
        client.view_overdue("exam_" + onderzoekTypeSelected.toLocaleLowerCase(), new Date().toISOString().slice(0, 10)).then((response:any) => {
            console.log(response)
            setOverdueRows(response.data)
        })
    }, [onderzoekTypeSelected])
    return (
        <TabPanel value={value!} sx={{height:'calc(100% - 49px)', padding:'5px'}}>
            <Grid sx={{gridTemplateRows:'1fr', gridTemplateColumns:'auto 225px', gridGap:'5px', height:'100%', width:'100%'}}>
                <ScreeningViewContainer 
                    overdueRows={overdueRows} 
                    upcomingRows={upcomingRows}
                />
                <ScreeningViewToolbar {...ToolbarProps}/>
            </Grid>
        </TabPanel>
    )
};

export default ScreeningPanelView;