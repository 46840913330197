const config = {}

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
  config.apiBasePath= 'http://localhost:8000/api'
  config.reactAppMode= 'dev'
  
}
else{
  config.apiBasePath= 'https://api.vervaecke.dev/api',
  config.reactAppMode= 'prod'
  
}

  
  export default config;