import * as React from 'react'

import { IDocPage, IFiletreeItem } from './interfaces/interfaces';
import DocTabs from './components/DocTabs';

import { client } from './client/client';

const DocPage : React.FC<IDocPage> = () => {
    const [filetree, setFiletree] = React.useState<IFiletreeItem[]>([])
    //const [selectedItem, setSelectedItem] = React.useState<number>()
    const GetFiletree = () => {
        client.filetree().then((data:any) => {
            setFiletree(data.data)
        })
    }

    React.useEffect(() => {
        GetFiletree()
    }, [])

    const props = {
        filetree, client
    }
    
    return (
        <DocTabs {...props}></DocTabs>
    )
}

export default DocPage;