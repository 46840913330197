import * as React from 'react';
import { useFormContext, Controller, FieldError } from 'react-hook-form';
import { Box, TextField, TextFieldProps, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

const CssTextField = styled(TextField)({
    margin: '10px',
    '& label.Mui-focused': {
      color: 'var(--primary-900)',
      fontWeight: 400,
    },
    '& .MuiInputBase-input': {
      borderColor: '#c8d0d4',
      backgroundColor: 'var(--primary-200)',
    },
    '& .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
        
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#d32f2f',
        },
      },
      '& fieldset': {
        borderColor: 'var(--neutral-900)',
        //backgroundColor: 'var(--primary-200)',
      },
      '&:hover fieldset': {
        border: '2px solid var(--primary-900)',
        
      },
      '&.Mui-focused fieldset': {
        border: '1px solid var(--primary-900)',
        
      },
    },
  });

type TextInputProps = {
    name: string;
    
  } & TextFieldProps;

const TextInput: React.FC<TextInputProps> = ({name, ...otherProps}) => {

    return (
        <FormControl sx={{padding:'0px'}}>
            <CssTextField
                {...otherProps}
                variant='outlined'
                
            />            
        </FormControl>
    )
}

export default TextInput