import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import Button from "@mui/material/Button";

import ScreeningAddRecordDialog from './ScreeningAddRecordDialog'

import config from '../../config';
import FastAPIClient from '../../client';
const client = new FastAPIClient(config);

import { FC } from 'react';

import {
    IPatientInfo, IColoscopieInfo, IScreeningRowFilter as IRowFilter
} from "../interfaces"

/*
export interface Data {
    name: string;
    dob: string;
    datum: string;
    ptnr: string;
    exam: string;
    id: number;
}
*/
export interface rowData {
    data : IPatientInfo[]
}
interface headCell {
    disablePadding: boolean;
    id: keyof IPatientInfo;
    label: string;
    numeric: boolean;
}
const headCells: readonly headCell[] = [
    {id: 'name', numeric: false, disablePadding: true, label: "Naam"},
    {id: 'dob', numeric: false, disablePadding: true, label: "Geboortedatum"},
    {id: 'ptnr', numeric: false, disablePadding: true, label: "Patiënten nr."},
    {id: 'datum', numeric: false, disablePadding: true, label: "Datum onderzoek"},
    {id: 'exam', numeric: false, disablePadding: true, label: "Type onderzoek"}
]

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IPatientInfo) => void;
    
    order: Order;
    orderBy: string;
    rowCount: number;
}

const ScreeningTableHead = (props : EnhancedTableProps) => {
    const { order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof IPatientInfo) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
        };
    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key = {headCell.id}
                        align = {headCell.numeric ? 'right' : 'center'}
                        padding = {headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}


interface ScreeningTableProps {
    data: IPatientInfo[]
    onderzoekTypeSelected: string
    selectedRow: number
    setSelectedRow: Function
    rowFilter:IRowFilter
    updateData:Function;
}

const ScreeningTable: FC<ScreeningTableProps> = ({data, onderzoekTypeSelected, selectedRow, setSelectedRow, rowFilter, updateData}) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof IPatientInfo>('datum');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rowCount, setRowCount] = React.useState(0);

    const [openAddRecordDialog, setOpenAddRecordDialog] = React.useState(false)
    const [addRecordDialogValues, setAddRecordDialogValues] = React.useState({})
    

    const [rowData, setRowData] = React.useState<IPatientInfo[]>(data)
    const [filteredRowData, setFilteredRowData] = React.useState<IPatientInfo[]>(data)
    
    React.useEffect(() => {
        setRowData(data)
    },[data])

    

    React.useEffect(() => {
        const temp:IPatientInfo[] = []
        rowData.forEach((v,k) => {
            var include:boolean = false;
            var filtered:boolean = false;
            if (rowFilter.any){
                if(
                    v.name.toLowerCase().includes(rowFilter.any.toLowerCase()) ||
                    v.dob.toLowerCase().includes(rowFilter.any.toLowerCase()) ||
                    v.ptnr.toLowerCase().includes(rowFilter.any.toLowerCase()) ||
                    v.datum.toLowerCase().includes(rowFilter.any.toLowerCase())
                ){
                    include = true;
                }
                filtered = true
            }
            if (rowFilter.name){
                if(v.name.toLowerCase().includes(rowFilter.name.toLowerCase())){
                    include = true;
                }
                else{
                    include=false
                }
                filtered = true
            }
            if (rowFilter.dob){
                if(v.dob.toLowerCase().includes(rowFilter.dob.toLowerCase())){
                    include = filtered ? include==true ? true : false: true;
                }
                else{
                    include=false
                }
                filtered = true
            }
            if (rowFilter.ptnr){
                if(v.ptnr.toLowerCase().includes(rowFilter.ptnr.toLowerCase())){
                    include = filtered ? include==true ? true : false: true;
                }
                else{
                    include=false
                }
                filtered = true
            }
            if (rowFilter.datum){
                if(v.datum.toLowerCase().includes(rowFilter.datum.toLowerCase())){
                    include = filtered ? include==true ? true : false: true;
                }
                else{
                    include=false
                }
                filtered = true
            }

            if (include || !filtered){
                temp.push(v)
            }
        })
        setFilteredRowData(sortRowData(temp))
    }, [rowData, rowFilter])
    
    const sortRowData = (rowData: IPatientInfo[], property: keyof IPatientInfo = orderBy, direction:string = order) => {
        const sortedRowData: IPatientInfo[] = [...rowData].sort((a:IPatientInfo, b:IPatientInfo) => {
            if (direction == 'desc'){
                if (a[property] < b[property]) return 1
                else if (a[property] > b[property]) return -1
            }else{
                if (a[property] < b[property]) return -1
                else if (a[property] > b[property]) return 1
            }
            return 0
        })
        return sortedRowData
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof IPatientInfo,
      ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setFilteredRowData(sortRowData(filteredRowData, property, isAsc ? 'desc' : 'asc'))
      };
    const addRecord = () => {
        setAddRecordDialogValues({})
        setOpenAddRecordDialog(true)
    }
    const addRow = (rowId:number) => {
        //console.log(rowData)
        //setRowData([rowData,addRecordDialogValues])
        var data = addRecordDialogValues as IPatientInfo
        data.id = rowId
        //setRowData([...Object.values(rowData), data])
        //setSelectedRow(rowId)
        updateData(data, rowId)
        setTimeout(() => {document.getElementsByClassName('Mui-selected')[0].scrollIntoView()}, 100)
    }
    const handleCloseAddRecordDialog = () => {
        //setAddRecordDialogValues(values)
        console.log(addRecordDialogValues)
        if (!('cancel' in addRecordDialogValues)){
            client.onderzoek_add(JSON.stringify(addRecordDialogValues)).then(  (response:any) => {
                addRow(response.data)
            })
        }
        setOpenAddRecordDialog(false)
        setAddRecordDialogValues({})
        
    }
    const handleRowClick = (event : React.MouseEvent<unknown>, examID : number) => {
        setSelectedRow(examID)
    }
    
    return (
        <Paper  elevation={6} style={{height:'100%'}}>
        <TableContainer style={{height:'calc(100% - 50px)'}}>
            
            <Table stickyHeader>
                
                <ScreeningTableHead 
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    
                    onRequestSort={handleRequestSort}
                    rowCount={rowCount}
                />
                <TableBody>
                    {Object.values(filteredRowData).map((row,index) => {
                        const color: string = row.initiated ? 'black' : 'red'
                        const rowColor : string = row.completed ? 'green' : 'black'
                        return(
                            <TableRow
                                onClick={(event) => {handleRowClick(event, row.id)}}
                                key={row.id}
                                selected={selectedRow == row.id}
                                
                            >
                                <TableCell sx={{padding:'8px 16px', color:(color == 'black' ? rowColor : color )}} key={row.id}>{row.id}</TableCell>
                                {Object.values(headCells).map((k,v) => (
                                    <TableCell sx={{padding:'8px 16px', color:rowColor }} key={k.id}>{row[k.id]}</TableCell>
                                ))}
                            </TableRow>
                        )
                    })}
                        


                </TableBody>
            </Table>
            
        </TableContainer>
        <Toolbar style={{
            padding:0,
            height: "50px",
            minHeight: "50px",
            flexDirection: "row-reverse",
            borderTop: "1px solid rgba(224, 224, 224, 1)"
        }}>
            <Button
                sx={{fontSize:40, padding:0, maxWidth:"40px", maxHeight:"40px", float:'right'}}
                onClick={addRecord}
            >+</Button>
        </Toolbar>
        <ScreeningAddRecordDialog 
            open={openAddRecordDialog} 
            handleClose={handleCloseAddRecordDialog}
            values={addRecordDialogValues}
            onderzoekTypeSelected={onderzoekTypeSelected}
        />
        </Paper >
    )
}

export default ScreeningTable;
