import React from 'react';
import {createRoot} from 'react-dom/client';




import './index.css';
import "./assets/fonts/spacia/Spacia-Regular.otf"

import App from './App';
import SQL from './utils/sql'


const container = document.getElementById('root');
const root = createRoot(container)
root.render(
    
      <App />
    
);