import FastAPIClient from "../../../client";
import config from "../../../config";

class Client extends FastAPIClient{
    filetree(){
        return this.apiClient.post('/docs/filetree')
    }
    get_doc_by_id(id: number){
        const form_data = new FormData()
        form_data.append("id",id.toString())
        return this.apiClient.post('/docs/get_doc_by_id', form_data)
    }
};

export const client = new Client(config);
export default Client