import * as React from 'react'
import { Tab, Box } from '@mui/material';
import { Unstable_Grid2 as Grid2 } from '@mui/material';
import { TabPanel } from '@mui/lab' 

import { ScreeningPanelProps } from './interfaces';

import ScreeningToolbar from './ScreeningToolbar';
import ScreeningTable from './ScreeningTable';
import ScreeningEditExamBox from './ScreeningEditExamBox';

const ScreeningPanelEdit: React.FC<ScreeningPanelProps> = ({...props}) => {
    const {
        value, rowData, onderzoekTypeSelected, 
        selectedRow, setSelectedRow, rowFilter,
        selectedRowData, formReset,
        updateData, onderzoekTypes, setOnderzoekTypeSelected,
        setRowFilter
    } = props

    const headerHeight= '125px';

    return (
        <TabPanel value={value!} sx={{height:'calc(100% - 49px)', padding:'5px'}}>
            <Grid2 container spacing={1} sx={{height:'100%'}}>
                <Grid2 xs={12} sx={{height:'fit-content'}}>
                    <Box sx={{height: headerHeight, background: 'var(--neutral-200)' }}>
                        <ScreeningToolbar 
                            onderzoekTypes={onderzoekTypes!}
                            onderzoekSelected={onderzoekTypeSelected!}
                            onderzoekSelect={setOnderzoekTypeSelected!}
                            setRowFilter={setRowFilter!}
                            {...props}
                        />
                    </Box>
                </Grid2>
                <Grid2 xs={6} sx={{height:`calc(100% - var(--Grid-columnSpacing) - ${headerHeight})`}}>
                    <Box sx={{background: 'var(--neutral-200)', height:'100%', padding:'5px' }}>
                        <ScreeningTable 
                        data= {rowData!}
                        onderzoekTypeSelected={onderzoekTypeSelected!}
                        selectedRow = {selectedRow!}
                        setSelectedRow={setSelectedRow!}
                        rowFilter={rowFilter!}
                        updateData={updateData}
                        
                        />
                    </Box>
                </Grid2>
                <Grid2 xs={6} sx={{height:`calc(100% - var(--Grid-columnSpacing) - ${headerHeight})`}}>
                    <Box sx={{background: 'var(--neutral-200)', height:'100%', padding:'5px' }}>
                        {selectedRow != 0 ? <ScreeningEditExamBox 
                            selectedRowData={selectedRowData!} 
                            formReset={formReset!}
                            setSelectedRow={setSelectedRow!}
                            updateData={updateData!}
                        /> : ''}
                    </Box>
                </Grid2>
            </Grid2>
        </TabPanel>
    )
};

export default ScreeningPanelEdit;