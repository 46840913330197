import * as React from 'react';
import { useFormContext, Controller, FieldError } from 'react-hook-form';
import { Box, TextField, TextFieldProps, FormControl, Switch, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface IDualToggle {
    labels: string[]
    value: boolean
    valueToggle: Function
}

const GridLabel = styled(Grid) ({
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    userSelect:"none",
    cursor:"pointer"
})

const DualToggle : React.FC<IDualToggle> = (props) => {
    const {labels, value, valueToggle} = {...props}
    return (
        <Grid container spacing={1} alignItems="center">
            <GridLabel item onClick={() => {valueToggle(!value)}}>{labels[0]}</GridLabel>
            <Grid item>
                <Switch 
                    checked={value}
                    onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                        valueToggle(event.target.checked)
                    }}
                
                />
            </Grid>
            <GridLabel item onClick={() => {valueToggle(!value)}}>{labels[1]}</GridLabel>
        </Grid>
    )
};

export default DualToggle;