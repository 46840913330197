import * as React from 'react'

export interface IConsoleLine{
    msg:string|undefined;
    origin?:string;
    status?:string;
    type?:string;
    widths?: number[];
    values?:string[];
    row?:number;
}
export const ConsoleLine  = (options:IConsoleLine) => {
    const defaults : IConsoleLine = {
        origin:'client',
        status:'success',
        msg: undefined,
        type: 'msg'
        

    }
    return {
        ...defaults, ...options
    }
}

export interface IDatabase{
    inputCMD:string;
    consoleLines:IConsoleLine[]
    setConsoleLines:Function
    setInputCMD:Function
    status?:number;
    setStatus?: Function
    database:string
    databases:string[]
    setDatabase:Function
    setDatabases:Function
    inputLines:string[]
    setInputLines:Function
    addInputLine:Function
}