import * as React from 'react'
import { TabPanel } from '@mui/lab'
import { styled } from '@mui/material/styles'

const DocCSSPanel = styled(TabPanel)({
    padding:'0',
    position: 'relative', 
    
})

export default DocCSSPanel;