import * as React from 'react';


import {
    Box,
    Typography,
    Stack,TextField,
    Link as MuiLink,
    FormControlLabel,
    Checkbox,
    Button,
    Select, MenuItem, SelectChangeEvent,
    InputLabel,
    FormControl
} from '@mui/material';

import TextInput from '../Inputs/TextInput'
import Grid from '../Containers/Grid'

import Toolbar from '@mui/material/Toolbar';

import Paper from '@mui/material/Paper';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


import ScreeningAddRecordDialog from './ScreeningAddRecordDialog'

import { FC } from 'react';
import { Moment } from 'moment';

interface ScreeningToolbarProps {
    onderzoekTypes: string[]
    onderzoekSelected: string
    onderzoekSelect: Function
    setRowFilter: Function
    dateStart:string;
    dateEnd:string;
    setDateStart:Function;
    setDateEnd:Function;
    filterInitiated:boolean;
    filterCompleted:boolean;
    setFilterInitiated:Function;
    setFilterCompleted:Function;
    getFilteredRows:Function;
}

const ScreeningToolbar : FC<ScreeningToolbarProps> = (props:ScreeningToolbarProps) => {
    const {onderzoekTypes, onderzoekSelected, onderzoekSelect, setRowFilter} = {...props}
    
    const onChange = (event:SelectChangeEvent) => {
        onderzoekSelect(event.target.value)
    }
    const [filterPlus, setFilterPlus] = React.useState<boolean>(true)
    //const [filterInitiated, setFilterInitiated] = React.useState<boolean>(false)
    const {
        filterInitiated, setFilterInitiated,
        filterCompleted, setFilterCompleted,
        dateStart, setDateStart,
        dateEnd, setDateEnd, getFilteredRows
    } = {...props}
    React.useEffect(() => {
        getFilteredRows()
    }, [filterInitiated, filterCompleted])
    return (
            
        <Grid
        sx={{gridTemplateColumns: '7fr 1fr 1fr 1fr', gridTemplateRows: '60px 60px', gridGap:'5px', padding:'0px 5px'}}
        >
            <TextInput 
                name="test"
                label="Filter"
                
                sx={{width:'400px', gridRow:1, gridColumn:1, height: '50px'}}
                onChange={(event) => {
                    var filterString:string = event.target.value || '';
                    var filter = Object()
                    var c = 0
                    filterString.match(/\w+:.*?(?=\s+\w+:|$)/g)?.forEach(e => {
                        var a = e.split(':')
                        filter[a[0]] = a[1]
                        c += 1
                    })
                    if (c == 0){
                        filter['any'] = filterString
                    }
                    
                    setRowFilter(filter)
                }}
            />
            <FormControlLabel
                control= {<Switch checked={filterPlus} onChange = {(event: React.ChangeEvent<HTMLInputElement>) => {setFilterPlus(event.target.checked)}}/>}
                label="Advanced filter"
                sx={{gridRow:2, gridColumn:1, ml:'5px'}}
            />
            <DesktopDatePicker
                label="Start Datum"
                inputFormat="YYYY-MM-DD"
                value={dateStart}
                onChange={(value: Moment | null, keyboardInputValue?: string | undefined) => {
                    setDateStart(value?.format("YYYY-MM-DD"))
                }}
                renderInput={(params) => <TextField {...params} 
                    sx={{gridRow:1, gridColumn:2, 
                        height: '50px', marginTop:'10px',
                        display: (filterPlus ? 'inline-flex':'none')
                    }}
                    onKeyDown={(event:any) => {
                        if (event.keyCode == 13)getFilteredRows()
                    }}
                    />
                }
                
            />
            
            <DesktopDatePicker
                label="Stop Datum"
                inputFormat="YYYY-MM-DD"
                value={dateEnd}
                onChange={(value: Moment | null, keyboardInputValue?: string | undefined) => {
                    setDateEnd(value?.format("YYYY-MM-DD"))
                }}

                renderInput={(params) => <TextField {...params} 
                    sx={{gridRow:1, gridColumn:3, height: '50px', marginTop:'10px',
                    display: (filterPlus ? 'inline-flex':'none')
                    }}
                    onKeyDown={(event:any) => {
                        if (event.keyCode == 13)getFilteredRows()
                    }}
                    />
                }
                
            />
            <FormControlLabel 
                    control={
                        <Checkbox checked={filterInitiated} 
                            onChange={(event) => {
                                setFilterInitiated(event.target.checked)
                            }}
                        />
                    }
                    label="Filter Initiated"
                    sx={{userSelect:'none', gridRow:2, 
                        gridColumn:2, marginLeft:'5px',
                        display: (filterPlus ? 'inline-flex':'none')
                    }}
                    
                />
            <FormControlLabel 
                    control={
                        <Checkbox checked={filterCompleted} 
                            onChange={(event) => {
                                setFilterCompleted(event.target.checked)
                            }}
                        />
                    }
                    label="Filter Completed"
                    sx={{userSelect:'none', gridRow:2, 
                        gridColumn:3, marginLeft:'5px',
                        display: (filterPlus ? 'inline-flex':'none')
                    }}
                    
                />
            <FormControl variant="standard" sx={{ m: 1, minWidth: 150, float:'right', gridColumn:4}}>
                
                <InputLabel id="select-onderzoek-type-label">Onderzoek Type</InputLabel>
                <Select
                    id="onderzoek-select-type"
                    labelId="onderzoek-select-type-label"
                    value={onderzoekSelected}
                    onChange={onChange}

                >
                    {onderzoekTypes.map((item,index) => {
                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <Button variant="contained" sx={{margin:'5px', paddingTop:'9px',
                fontFamily:'Rubik', fontWeight:'400', gridColumn:4}}
                onClick = {() => {getFilteredRows()}}
            >Refresh</Button>
        </Grid>
    )
};

export default ScreeningToolbar