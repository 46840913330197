import config from './config';
import jwtDecode from 'jwt-decode';
import * as moment from 'moment';
import { history } from './utils/utils';

const axios = require('axios');


class FastAPIClient {
  constructor(overrides) {
    this.config = {
      ...config,
      ...overrides,
    };
    this.authToken = config.authToken;

    this.login = this.login.bind(this);
    this.apiClient = this.getApiClient(this.config);
  }

  /* ----- Authentication & User Operations ----- */

  /* Authenticate the user with the backend services.
	 * The same JWT should be valid for both the api and cms */
  token(){
      return this.apiClient
      .post('/token')
      /*
      .then((response) => {
        //localStorage.setItem('token', JSON.stringify(response.data));
        //return this.fetchUser();
        return response.data
      });
      */
  }
  login(username, password, persistUser) {
    delete this.apiClient.defaults.headers['Authorization'];

    // HACK: This is a hack for scenario where there is no login form
    const form_data = new FormData();
    const grant_type = 'password';
    const item = {grant_type, username, password};
    for (const key in item) {
      form_data.append(key, item[key]);
    }
    form_data.append("persistUser", persistUser)
    
    return this.apiClient
        .post('/login', form_data)
  }

  fetchUser() {
    return this.apiClient.post('/auth/me').then(({data}) => {
      //debugger
      //localStorage.setItem('user', JSON.stringify(data));
      return data;
    });
  }

  register(email, password, fullName) {
    const registerData = {
      email,
      password,
      full_name: fullName,
      is_active: true,
    };

    return this.apiClient.post('/auth/signup', registerData).then(
        (resp) => {
          return resp.data;
        });
  }

  // Logging out is just deleting the jwt.
  logout() {
    // Add here any other data that needs to be deleted from local storage
    // on logout
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  onderzoeken_all(onderzoek_type) {
      const form_data = new FormData();
          form_data.append("exam",onderzoek_type)
      return this.apiClient.post('/screening/onderzoeken_all', form_data)
  }
  onderzoeken_filtered(filter) {
    const form_data = new FormData();
        form_data.append("filter",JSON.stringify(filter))
    return this.apiClient.post('/screening/onderzoeken_filtered', filter)
}
  onderzoek_add(data){
      const form_data = new FormData();
      form_data.append("data",data)
      return this.apiClient.post('/screening/onderzoek_add', form_data)
  }
  onderzoek_types(){
      return this.apiClient.post('/screening/onderzoek_types')
  }

  onderzoek_get_by_id(examId){
    const form_data = new FormData();
    form_data.append("examId",examId)
    return this.apiClient.post('/screening/onderzoek_by_id', form_data)

  }
  onderzoek_update_by_id(exam){
      const form_data = new FormData()
      form_data.append('exam', JSON.stringify(exam.exam))
      form_data.append('examInfo', JSON.stringify(exam.examInfo))
      return this.apiClient.post('/screening/onderzoek_update_by_id', form_data)
      
  }
  database_query(database, msg){
    const form_data = new FormData()
    form_data.append('msg', msg)
    form_data.append('database', database)
    return this.apiClient.post('/database/query', form_data)
  }
  database_switch(database){
    const form_data = new FormData()
    form_data.append('database', database)
    return this.apiClient.post('/database/switch', form_data)
  }
  database_list(){
    return this.apiClient.post('/database/list')
  }
  database_hash(hash){
    const form_data = new FormData()
    form_data.append('hash', hash)
    return this.apiClient.post('/database/hash', form_data)
  }

  /* ----- Client Configuration ----- */

  /* Create Axios client instance pointing at the REST api backend */
  getApiClient(config) {
    const initialConfig = {
      baseURL: `${config.apiBasePath}`,
    };
    const client = axios.create(initialConfig);
    
    
    client.interceptors.request.use(localStorageTokenInterceptor);
    client.interceptors.response.use(
      response => response,
      error => {
        
        switch(error.response.status){
          case 401:
            document.cookie = "redirecturl="+window.location.pathname
            history.navigate("/login")
          default:
            return Promise.reject(error)
        }
      }
    )
    return client;
  }

  
}


// every request is intercepted and has auth header injected.
function localStorageTokenInterceptor(config) {
  const headers= {};
  const tokenString = localStorage.getItem('token');
  
  if (tokenString) {
    const token = JSON.parse(tokenString);
    const decodedAccessToken = jwtDecode(token.access_token);
    const isAccessTokenValid =
			moment.unix(decodedAccessToken.exp).toDate() > new Date();
    if (isAccessTokenValid) {
      headers['Authorization'] = `Bearer ${token.access_token}`;
    } else {
      alert('Your login session has expired');
    }
  }
  config['headers'] = headers;
  config.withCredentials = true
  
  return config;
}

export default FastAPIClient;