import * as React from 'react'
import { styled, Theme } from '@mui/material/styles';

const VerslagToolbarStyled = styled('div', {
    name: "VerslagToolbar",
    slot: "VerslagToolbar"
})(({theme}) => ({
    background: "rgba(170, 170, 170, 0.5)",
    gridColumn: "span 2"
}))

interface IVerslagToolbar {

}

const VerslagToolbar : React.FC = (props) => {

    return (
        <VerslagToolbarStyled>
            
        </VerslagToolbarStyled>
    )
};

export default VerslagToolbar;