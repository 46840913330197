import * as React from 'react'
import config from '../../config';
import FastAPIClient from '../../client';
export const client = new FastAPIClient(config);

import {
    Container,
    Grid,
    Box,
    Typography,
    Stack,
    Link as MuiLink,
    FormControlLabel,
    Checkbox,
    Button
  } from '@mui/material';
  
import Grid2 from '@mui/material/Unstable_Grid2';
import { FC, useEffect, useState } from 'react';
import ScreeningTable  from '../../components/Screening/ScreeningTable';
import ScreeningToolbar  from '../../components/Screening/ScreeningToolbar';
import ScreeningEditExamBox from '../../components/Screening/ScreeningEditExamBox'
import {rowData as RowData} from '../../components/Screening/ScreeningTable';
import {
    IPatientInfo, IColoscopieInfo, IScreeningRowFilter as IRowFilter,
    IScreeningExam
} from "../../components/interfaces"
import { ScreeningPageProps} from "../../components/Screening/interfaces"

import { JsonObjectExpression } from 'typescript';
import { AxiosResponse } from 'axios';
import { ZodFunction } from 'zod';

import { ScreeningPanelProps } from '../../components/Screening/interfaces';
import ScreeningPanelView from '../../components/Screening/ScreeningPanelView';
import ScreeningPanelEdit from '../../components/Screening/ScreeningPanelEdit';
import {Tab, Tabs} from '@mui/material'
import { TabContext, TabList, TabPanel} from '@mui/lab'

import { styled } from '@mui/material/styles';
import VerslagPanel from '../../components/Verslagen';





interface dataReponse {
    data: IPatientInfo[]
}

interface IExam {
    exam? :IPatientInfo;
    examInfo? : any;
}


const CSSTabList = styled(TabList)({
    "& .MuiButtonBase-root" : {
        //backgroundColor:"var(--neutral-800)",
        borderTopLeftRadius:'10px',
        borderTopRightRadius:'10px',
        boxShadow: '0 0 2px 0px var(--neutral-900)',
    },
    "& .MuiTabs-scroller" : {
        padding: '5px 10px 0px 10px',
        maxHeight:'48px',

    },
    "& .MuiButtonBase-root.Mui-selected" : {
        backgroundColor: 'rgba(0,0,50,0.045)'
    }
})



const ScreeningPage: FC = () => {
    const headerHeight='125px';
    const [rowData, setRowData] = useState<IPatientInfo[]>([])
    const [onderzoekTypeSelected, setOnderzoekTypeSelected] = useState<string>('')
    const [onderzoekTypes, setOnderzoekTypes] = useState<string[]>([])
    const [selectedRow, setSelectedRow] = useState<number>(0)
    const [selectedRowData, setSelectedRowData] = useState<IScreeningExam>()
    const [formReset, setFormReset] = useState<boolean>(false)
    const [rowFilter, setRowFilter] = useState<IRowFilter>({
        name:'', dob:'', ptnr:'', datum:''
    })
    const [panelIndex, setPanelIndex] = useState<string>('0')
    const [filterInitiated, setFilterInitiated] = useState<boolean>(false)
    const [filterCompleted, setFilterCompleted] = useState<boolean>(false)
    const today = new Date()//.toISOString().substring(0,10)
    const year = today.getFullYear()
    const month = today.getMonth()

    const [dateStart, setDateStart] = useState<string>(new Date(Date.UTC(year, month, 1)).toISOString().substring(0,10))
    const [dateEnd, setDateEnd] = useState<string>(new Date(Date.UTC(year, month + 1, 0)).toISOString().substring(0,10))
    
    const panels = [{c: ScreeningPanelEdit, n:"Edit"}, {c:ScreeningPanelView, n:"View"}, {c:VerslagPanel, n:"Verslag"}]

    const getFilteredRows = () => {
        var filter = {
            exam: onderzoekTypeSelected,
            ...(dateEnd.match(/\d{4}-\d{2}-\d{2}/) && {datumend: dateEnd}),
            ...(dateStart.match(/\d{4}-\d{2}-\d{2}/) && {datumstart: dateStart}),
            ...(filterCompleted && {completed:filterCompleted}),
            ...(filterInitiated && {initiated:filterInitiated}),

        }
        
        client.onderzoeken_filtered(filter).then((response:dataReponse) => {
            setRowData(response.data)
        })
    }

    useEffect(() => {
        //client.onderzoeken_all('Coloscopie').then((response:dataReponse) => {
        //    setRowData(response.data)
        //})
        client.onderzoek_types().then((response:any) => {
            setOnderzoekTypes(response.data)
            setOnderzoekTypeSelected('Coloscopie')
            
        })
    }, [])

    useEffect(() => {
        if (onderzoekTypeSelected.length == 0) return
        getFilteredRows()
    }, [onderzoekTypeSelected])

    

    useEffect(() => {
        if (selectedRow == 0)return;
        client.onderzoek_get_by_id(selectedRow).then((response:AxiosResponse) => {
            setSelectedRowData(response.data)
        })
    }, [selectedRow])
    
    const updateData = (data: IPatientInfo, selectedRow: number = 0) => {
        /*const temp: IPatientInfo[] = JSON.parse(JSON.stringify(rowData))*/
        var newRow:boolean = true;
        var newRowData: IPatientInfo[] =  rowData.map((v,k) => {
            if (v.id == data.id){
                //rowData[k] = data
                newRow = false
                return data
            }
            return v
        })
        if (newRow) newRowData = [...newRowData, data]
        setRowData(newRowData)
        setFormReset(true)
        setSelectedRow(selectedRow)
        
        //setTimeout(() => {setFormReset(false)}, 500)
    }
    const props: ScreeningPanelProps = {
        rowData, setRowData, 
        onderzoekTypes, setOnderzoekTypes,
        onderzoekTypeSelected, setOnderzoekTypeSelected,
        selectedRow, setSelectedRow, 
        rowFilter, setRowFilter,
        selectedRowData, setSelectedRowData,
        formReset, setFormReset,
        updateData,
        dateStart, setDateStart,
        dateEnd, setDateEnd,
        filterInitiated, setFilterInitiated,
        filterCompleted,setFilterCompleted,
        getFilteredRows
    }
    const EditPanelProps: ScreeningPanelProps = {...props}
    const ViewPanelProps: ScreeningPanelProps = {...props}
    
    return (
        
        <Box sx={{width:'100vw', height:'calc(100vh - 70px)'}} >
            <TabContext value={panelIndex}>
                <Box sx={{borderBottom:'1px solid black'}}>
                    <CSSTabList onChange={(event: React.SyntheticEvent, newIndex:string) => {setPanelIndex(newIndex)}}>
                        {
                            panels.map((info, index) => {
                                const label:string = info.n
                                if (info.n =="Verslag" && window.location.hostname != "localhost") return <React.Fragment/>
                                return <Tab label={label} key={index} value={index.toString()}/>
                            })
                        }
                    </CSSTabList>
                </Box>
                <ScreeningPanelEdit value="0" {...EditPanelProps}/>
                <ScreeningPanelView value="1" {...ViewPanelProps}/>
                {window.location.hostname == "localhost" ? <VerslagPanel value="2"/> : ''}
            </TabContext>
        </Box>
        
    );
    /*
    return (
    <Container maxWidth={false} sx={{height:'100%', maxHeight:'calc(100vh - 70px)'}}>
        
        <Grid2 container spacing={1} sx={{height:'100%'}}>
            <Grid2 xs={12} sx={{height:'fit-content'}}>
                <Box sx={{height: headerHeight, background: 'var(--neutral-200)' }}>
                    <ScreeningToolbar 
                            onderzoeken={onderzoekTypes}
                            onderzoekSelected={onderzoekTypeSelected}
                            onderzoekSelect={setOnderzoekTypeSelected}
                            setRowFilter={setRowFilter}
                    />
                </Box>
            </Grid2>
            <Grid2 xs={6} sx={{height:`calc(100% - var(--Grid-columnSpacing) - ${headerHeight})`}}>
                <Box sx={{background: 'var(--neutral-200)', height:'100%', padding:'5px' }}>
                    <ScreeningTable 
                       data= {rowData}
                       onderzoekTypeSelected={onderzoekTypeSelected}
                       selectedRow = {selectedRow}
                       setSelectedRow={setSelectedRow}
                       rowFilter={rowFilter}
                    />
                </Box>
            </Grid2>
            <Grid2 xs={6} sx={{height:`calc(100% - var(--Grid-columnSpacing) - ${headerHeight})`}}>
                <Box sx={{background: 'var(--neutral-200)', height:'100%', padding:'5px' }}>
                    {selectedRow != 0 ? <ScreeningEditExamBox 
                        selectedRowData={selectedRowData} 
                        formReset={formReset}
                        setSelectedRow={setSelectedRow}
                        updateData={updateData} 
                    /> : ''}
                </Box>
            </Grid2>
        </Grid2>
    </Container>
    );
    */
  };

  export default ScreeningPage;