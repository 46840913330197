import * as React from 'react';
import {appTheme} from './themes/theme';
import {CssBaseline, ThemeProvider } from "@mui/material";

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Home from "./views/Home";
import LoginPage from "./views/Login";
import ScreeningPage from "./views/Screening"
import DatabasePage from './views/database';
import TarificatiePage from './views/tarificatie';
import DocPage from './views/docs';

import DashboardHeader from "./components/DashboardHeader";
import {Container} from "@mui/material"

import { useNavigate, Link } from 'react-router-dom';
import FastAPIClient from './client';
import config from './config';
import jwtDecode from "jwt-decode";
import * as moment from "moment";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';



const client = new FastAPIClient(config);

interface IUser {
    username: string
    email: string
    avatar: string
}

const App: React.FC = () => {
    const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [activeUser, setActiveUser] = React.useState<IUser>({username: '', email: '', avatar: ''})

    
	
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={appTheme}>
        
        <Container className="App bg-black"
            maxWidth={false} 
            sx={{
                height:'100vh', 
                maxHeight:'100vh',
                display:'grid',
                width:'100vw',
                padding:'0px !important',
                gridTemplateRows:'min-content' 
            }}
        >
            
            <Router>
                
                <DashboardHeader setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} activeUser={activeUser} setActiveUser={setActiveUser} />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<LoginPage setActiveUser={setActiveUser} />} />
                    {isLoggedIn ? <Route path="/screening" element={<ScreeningPage />} /> : '' }
                    {isLoggedIn ? <Route path="/database" element={<DatabasePage />} /> : '' }
                    {isLoggedIn ? <Route path="/tarificatie" element={<TarificatiePage />} /> : '' }
                    {isLoggedIn ? <Route path="/docs" element={<DocPage />} /> : '' }
                
                </Routes>
            </Router>
        </Container>
        </ThemeProvider>
        </LocalizationProvider>
    );
};

export default App;