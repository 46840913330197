import * as React from 'react'
import {
    Button, TextField, Paper, Divider
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2';

import ColoscopieComponent from "./ScreeningColoscopieComponent"

import {
    IColoscopieInfo,
    IPatientInfo,
    IScreeningExam
} from "../interfaces"
import {
    calcAge
} from '../../utils/utils'
import {client} from '../../views/Screening'

interface ICPatientInfo {
    info: IPatientInfo
}

const PatientInfo: React.FC <ICPatientInfo> = ({info}) => {
    

    const [age, setAge] = React.useState<number>(0)
    const [name, setName] = React.useState<string>('')
    const [dob, setDob] = React.useState<string>('')
    const [ptnr, setPtnr] = React.useState<string>('')
    const [datum, setDatum] = React.useState<string>('')
    const [exam, setExam] = React.useState<string>('')
    const [correct, setCorrect] = React.useState<boolean>(true)
    
    React.useEffect(() => {
        if (info.dob == undefined) return
        setAge(calcAge(info.dob, info.datum))
        setName(info.name)
        setDob(info.dob)
        setPtnr(info.ptnr)
        setDatum(info.datum)
        setExam(info.exam)
    }, [info])

    const updateDatum = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        var val = event.target.value
        console.log(val)
        let m: any
        if (/(0[1-9]|[12][0-9]|3[01])[-\/\\\.](0[1-9]|1[0-2])[-\/\\\.](\d{4})/.test(val)){
            m = val.match(/(0[1-9]|[12][0-9]|3[01])[-\/](0[1-9]|1[0-2])[-\/](\d{4})/)
            info.datum = `${m[3] as string}-${m[2].padStart(2,'0')}-${m[1].padStart(2,'0')}`
            setDatum(info.datum)
            setCorrect(true)
            return
        }
        else if(/(\d{4})[-\/](0[1-9]|1[0-2]|[1-9])[-\/](0[1-9]|[12][0-9]|3[01])/.test(val)){
            m = val.match(/(\d{4})[-\/](0[1-9]|1[0-2]|[1-9])[-\/](0[1-9]|[12][0-9]|3[01])/)
            
            info.datum = `${m[1]}-${m[2].padStart(2,'0')}-${m[3].padStart(2,'0')}`
            setDatum(info.datum)
            setCorrect(true)
            return
        }
        setCorrect(false)
        setDatum(val)
    }

    return (
        <Grid2 container spacing={2} >
            
            <Grid2 xs={4}><TextField disabled variant='outlined' focused fullWidth label="Patient" value={name} InputLabelProps={{ shrink: true }} /></Grid2>
            <Grid2 xs={4}><TextField disabled variant='outlined' focused fullWidth label="Geboorte Datum" value={`${dob} (${age})`} InputLabelProps={{ shrink: true }} /></Grid2>
            <Grid2 xs={4}><TextField disabled variant='outlined' focused fullWidth label="Patient nr." value={ptnr} InputLabelProps={{ shrink: true }} /></Grid2>
            <Grid2 xs={4}><TextField error={!correct } helperText={correct ? "": "Incorrecte datum"} variant='outlined' fullWidth label="Datum Onderzoek" value={datum} InputLabelProps={{ shrink: true }} onChange={updateDatum}/></Grid2>
            <Grid2 xs={4}><TextField disabled variant='outlined' focused fullWidth label="Type Onderzoek" value={exam} InputLabelProps={{ shrink: true }} /></Grid2>
            
        </Grid2>
    )
}




/*
interface IScreeningExam {
    exam? : any
    examInfo? : any
}
*/
interface IExam {
    selectedRowData: IScreeningExam
    setSelectedRow:Function
    updateData:Function
    formReset:boolean;
}

const SelectedExamComponent = (patientInfo:IPatientInfo, examInfo:any, selectedRowData:IScreeningExam, formReset:boolean) => {
    if (! patientInfo) return
    switch (patientInfo.exam){
        case 'Coloscopie':
            return <ColoscopieComponent 
                info={examInfo as IColoscopieInfo}
                datum={patientInfo.datum}
                selectedRowData={selectedRowData}
                formReset={formReset}
            />
        
        default:
            return <></>
    }
}

const ScreeningEditExamBox : React.FC <IExam> = ({selectedRowData, setSelectedRow, updateData, formReset}) => {
    if (!selectedRowData) return <></>;
    const patientInfo :IPatientInfo = selectedRowData?.exam
    const examInfo:any = selectedRowData?.examInfo

    const onOk = () => {
        client.onderzoek_update_by_id({exam: patientInfo, examInfo:examInfo}).then(() => {
            patientInfo.initiated = true
            updateData(patientInfo)
        })
    }
    
    
    return (
        <Paper sx={{marginTop:'10px', padding:'10px'}}>
            <PatientInfo info={selectedRowData.exam}/>
            <Divider sx={{marginBlock:'10px'}}/>
            {SelectedExamComponent(patientInfo, examInfo, selectedRowData, formReset)}
            <Divider sx={{marginBlock:'10px'}}/>
            <Grid2 container spacing={2} direction='row-reverse'>
                <Grid2 ><Button onClick={() => {setSelectedRow(0)}}>Cancel</Button></Grid2>
                <Grid2 ><Button onClick={onOk}>Ok</Button></Grid2>
            </Grid2>

        </Paper>
        
    )
}

export default ScreeningEditExamBox