import {
    Container,
    Grid,
    Box,
    Typography,
    Stack,
    Link as MuiLink,
    FormControlLabel,
    Checkbox,
  } from '@mui/material';
  
  import Button from '@mui/material/Button';
  import { FC, useState } from 'react';
  import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
  import { Link, useNavigate } from 'react-router-dom';
  import { boolean, literal, object, string, TypeOf } from 'zod';
  import FormInput from '../../components/FormInput';
  import styled from '@emotion/styled';
  import { zodResolver } from '@hookform/resolvers/zod';
  import config from '../../config';
  import parseCookie from '../../utils/cookie'
  import updateSessionStorage from '../../utils/events'

  import FastAPIClient from '../../client';

  import DashboardHeader from "../../components/DashboardHeader";

  

// 👇 Styled React Route Dom Link Component
export const LinkItem = styled(Link)`
  text-decoration: none;
  color: #3683dc;
  &:hover {
    text-decoration: underline;
    color: #5ea1b6;
  }
`;

//const client = new FastAPIClient(config);

class Client extends FastAPIClient{
    validate_passcode(username:string, passcode:string){
        const form_data = new FormData()
        form_data.append("username", username)
        form_data.append("passcode", passcode)
        return this.apiClient.post('/validate_passcode', form_data)
    }
};

const client = new Client(config);


const loginSchema = object({
  username: string().min(1,'Username is required'),
  password: string()
    .min(1,'Password is required')
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  persistUser: boolean(),
  
});

const passcodeSchema = object({
  passcode: string()
    .min(6, "Passcode must be 6 digits")
    .max(6, "Passcode must be 6 digits")
})

type ILogin = TypeOf<typeof loginSchema>;
type IPasscode = TypeOf<typeof passcodeSchema>;

interface ILoginPage {
  setActiveUser: Function
}

const LoginPage: FC<ILoginPage> = (props) => {

    const [loginError, setLoginError] = useState<string>('')
    const [display, setDisplay] = useState<string>("password")
    const [username, setUsername] = useState<string>('')
    const navigate = useNavigate()
    const defaultValues = {
      username: '',
      password: '',
      persistUser: false,
      passcode: ''
    };
    const {setActiveUser} = props
  
    // 👇 The object returned from useForm Hook
    const methods = useForm<ILogin>({
      resolver: zodResolver(loginSchema),
      defaultValues,
    });

    const passcodeMethods = useForm<IPasscode>({
      resolver: zodResolver(passcodeSchema),
      defaultValues
    })
  
    // 👇 Submit Handler
    const onSubmitHandler: SubmitHandler<ILogin> = (values: ILogin) => {
      //console.log(methods.formState.errors)
      client.login(values.username, values.password, values.persistUser).then(()=>{
        setUsername(values.username)
        setDisplay('passcode')
        /*
        sessionStorage.setItem('isLoggedIn',"true")
        window.dispatchEvent(new Event("updateSessionStorage"))
        setLoginError('')
        
        var cookie = document.cookie ? parseCookie(document.cookie) : {}
        if (cookie.redirecturl && cookie.redirecturl.toLocaleLowerCase() != '/login'){
          document.cookie = "redirecturl=;max-age=1"
          navigate(cookie.redirecturl)
          
        }else{
          navigate('/')
        }
        */
        

      }).catch((response:any) => {
        var msg = response.response.data.msg
        methods.clearErrors()
        switch(msg){
          case 'Incorrect Username or Password':
            methods.setError("username" , {message:""})
            methods.setError("password" , {message:"Incorrect Username or Password"})
            
            break;
          case 'Banned or disabled User':
              methods.setError("username" , {message:"Banned or disabled User"})
              break;
          case 'Banned IP-address':
                methods.setError("username" , {message:"Banned IP-address"})
                break;

        }
      })
    };

    const onPasscodeHandler: SubmitHandler<IPasscode> = (values: IPasscode) => {
      client.validate_passcode(username, values.passcode).then(() => {
        sessionStorage.setItem('isLoggedIn',"true")
        window.dispatchEvent(new Event("updateSessionStorage"))
        setLoginError('')
        
        var cookie = document.cookie ? parseCookie(document.cookie) : {}
        if (cookie.redirecturl && cookie.redirecturl.toLocaleLowerCase() != '/login'){
          document.cookie = "redirecturl=;max-age=1"
          navigate(cookie.redirecturl)
          
        }else{
          navigate('/')
        }
      }).catch((response:any) => {
        var msg = response.response.data.msg
        passcodeMethods.clearErrors()
        switch(msg){
          case 'Incorrect Passcode':
            passcodeMethods.setError("passcode" , {message:"Incorrect Passcode"})
            break;
          

        }
      })
    }
  
    // 👇 JSX to be rendered
    return (
      
      <Container
        maxWidth={false}
        sx={{ height: '100%', backgroundColor: { xs: '#fff', md: '#f4f4f4' }, padding: '0px !important'}}
      >
        
        
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          sx={{ width: '100%', height: '100%' }}
        >
          <Grid
            item
            sx={{ maxWidth: '60rem', width: '100%', backgroundColor: '#fff', transform: 'translateY(-30px)' }}
          >
            
            <FormProvider {...methods} >
              <Grid
                container
                sx={{
                  boxShadow: { sm: '0 0 5px #ddd' },
                  py: '6rem',
                  px: '1rem',
                  display: display == 'password' ? "grid" : "none"
                }}
              >
                <Grid
                  item
                  container
                  justifyContent='space-between'
                  rowSpacing={5}
                  sx={{
                    maxWidth: { sm: '45rem' },
                    marginInline: 'auto',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{margin:'auto'}}
                  >
                    
                    <Box
                      display='flex'
                      flexDirection='column'
                      component='form'
                      noValidate
                      autoComplete='off'
                      sx={{ paddingRight: { sm: '3rem' } }}
                      onSubmit={methods.handleSubmit(onSubmitHandler)}
                    >
                      
                        <Typography
                          variant='h6'
                          component='h1'
                          sx={{ textAlign: 'center', mb: '1.5rem' }}
                        >
                          Log into your account
                        </Typography>
                        <FormInput
                          label='Username'
                          type='username'
                          name='username'
                          //InputProps={{ disableUnderline: true }}
                          placeholder='Gebruiker'
                          focused
                          required
                        />
                        <FormInput
                          type='password'
                          label='Password'
                          name='password'
                          placeholder="*********"
                          autoComplete='off'
                          required
                          focused
                        />
                      <FormControlLabel
                        control={
                          <Checkbox
                            size='small'
                            aria-label='trust this device checkbox'
                            required
                            {...methods.register('persistUser')}
                          />
                        }
                        label={
                          <Typography
                            variant='body2'
                            sx={{
                              fontSize: '0.8rem',
                              fontWeight: 400,
                              color: '#5e5b5d',
                              lineHeight:'1rem'
                            }}
                          >
                            Trust this device
                          </Typography>
                        }
                      />
                    <Button variant="contained"
                      type='submit'
                      sx={{maxWidth:'150px', width:'100%', margin:'auto'}}
                      onClick={methods.handleSubmit(onSubmitHandler)}
                    >
                      Login
                    </Button>
                      
                    </Box>
                    
                  </Grid>
                  
                </Grid>
                { false && 
                <Grid container justifyContent='center'>
                  <Stack sx={{ mt: '3rem', textAlign: 'center' }}>
                    <Typography sx={{ fontSize: '0.9rem', mb: '1rem', color:'var(--neutral-500)'}}>
                      Need an account?{' '}
                      <LinkItem to='/signup'>Sign up here</LinkItem>
                    </Typography>
                    <Typography sx={{ fontSize: '0.9rem', color:'var(--neutral-500)' }}>
                      Forgot your{' '}
                      <LinkItem to='/forgotPassword'>password?</LinkItem>
                    </Typography>
                  </Stack>
                </Grid>
                }
              </Grid>
            </FormProvider>
            
            <FormProvider {...passcodeMethods}>
              <Grid
                container
                sx={{
                  boxShadow: { sm: '0 0 5px #ddd' },
                  py: '6rem',
                  px: '1rem',
                  display: display == 'passcode' ? "grid" : "none"
                }}
              >
                <Grid
                  item
                  container
                  justifyContent='space-between'
                  rowSpacing={5}
                  sx={{
                    maxWidth: { sm: '45rem' },
                    marginInline: 'auto',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{margin:'auto'}}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      component='form'
                      noValidate
                      autoComplete='off'
                      sx={{ paddingRight: { sm: '3rem' } }}
                      onSubmit={passcodeMethods.handleSubmit(onPasscodeHandler)}
                    >
                      <Typography
                        variant='h6'
                        component='h1'
                        sx={{ textAlign: 'center', mb: '1.5rem' }}
                      >
                        Enter passcode for validation
                      </Typography>
                      
  
                      
                      <FormInput
                        type='text'
                        label='Passcode'
                        name='passcode'
                        placeholder="passcode"
                        autoComplete='off'
                        required
                        focused
                        fontSize= "24px"
                        paddingTop="6px"
                        paddingBottom="6px"
                        
                      />
                      <Button variant="contained"
                      type='submit'
                      sx={{maxWidth:'150px', width:'100%', margin:'auto'}}
                      onClick={passcodeMethods.handleSubmit(onPasscodeHandler)}
                    >
                      Validate
                    </Button>
                    </Box>
                    </Grid>
                  
                  </Grid>     
                  </Grid>
            </FormProvider>
            
          </Grid>
        </Grid>
      </Container>
    );
  };
  
  export default LoginPage;