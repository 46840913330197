import * as React from 'react'
import { styled, Theme } from '@mui/material/styles';
import { Client } from '.';
import { IVerslagCategoryGroups, IVerslagItemGroup, IVerslagItemGroups, IVerslagOption, IVerslagOptionGroup, IVerslagOptionTree, IVerslagSelectorGroup } from './interfaces';
import HeaderItem from './VerslagEditor/Header';




const VerslagEditorStyled = styled('div', {
    name: "VerslagEditor",
    slot: "VerslagEditor"
})(({theme}) => ({
    background: "var(--front-100)",//"rgba(255,255,255, 0.9)",
    gridColumn: 1
}))

const SelectComponent = (selector:string, optionTree: IVerslagOption[][]) => {
    console.log(selector,optionTree)
    if (/@?-\d{2}->d{2}/i.test(selector)){
        console.log("noheader")
    }else{
        return optionTree.map(o => {
            //if (o.selector == selector ) return o.value
        })
    }
    return <React.Fragment />
}


interface IVerslagEditor {
    client: Client
}

const VerslagEditor: React.FC<IVerslagEditor> = (props) => {
    const {client} = props
    const [verslagType, setVerslagType] = React.useState<string>("Gastroscopie")
    const [categories, setCategories] = React.useState<string[]>([])
    const [optionTree, setOptionTree] = React.useState<IVerslagOptionTree[]>([])
    const [categoryGroups, setCategoryGroups] = React.useState<IVerslagCategoryGroups>({})
    
    //const [itemGroups, setItemGroups] = React.useState<IVerslagItemGroups>()

    React.useEffect(() => {
        switch (verslagType) {
            case "Gastroscopie":
                setCategories(["gastroscopie"])
                break;
            case "Coloscopie":
                setCategories(["sedatie", "coloscopie"])
                break;
            default:
                setCategories([])

        }
    }, [verslagType])

    React.useEffect(() => {
        setOptionTree([])
        categories.forEach(cat => {
            client.get_option_tree(cat).then((response:any) => {
                var data = response.data.map((v:any) => {
                    v.options = v.options.split(';').filter((e:string) => e.length >0)
                    return v
                })
                setOptionTree(previous => [...previous, {category: cat, tree: data}])
            })
        })
    }, [categories])

    React.useEffect(() => {

        var tempCategoryGroups:IVerslagCategoryGroups = {}
        optionTree.forEach(oTree => {
            var tempItemGroups:IVerslagItemGroups = {length: 0, keys: [], itemGroups: {}} 
            
            var headerItem: IVerslagOption = oTree.tree.find(o => o.type == "header") as IVerslagOption
            var next_option:IVerslagOption|undefined = oTree.tree.find(o => o.selector == headerItem.next_option)
            var tempItemGroup:IVerslagItemGroup
            while (next_option!=undefined){
                tempItemGroups.length += 1
                tempItemGroups.keys.push(next_option.subCategory)
                tempItemGroup = {
                    name: next_option.description,
                    label: next_option.description,
                    options: [next_option],
                    selected: [],
                    selectedIds: [],
                    selectorGroups: [],
                    optionGroups: [],
                    initialItem: next_option.options,
                    nextItem: oTree.tree.find(o=> o.selector ==next_option?.next_option)?.subCategory as string
                }
                tempItemGroups.itemGroups[next_option.subCategory] = tempItemGroup
                next_option = oTree.tree.find(o => o.selector == next_option?.next_option)
            }
            
            
            
            oTree.tree.forEach(option => {
                if (option.type == "header" || option.type=="item"){
                    return
                }
                var tempItemGroup:IVerslagItemGroup = tempItemGroups.itemGroups[option.subCategory]
                var selectorGroup: IVerslagSelectorGroup
                var optionGroup: IVerslagOptionGroup
                if (tempItemGroup){
                    tempItemGroup.options.push(option)
                    if (option.type == "item") tempItemGroup.initialItem = option.options
                    selectorGroup = tempItemGroup.selectorGroups.filter(g => g.name == option.selector)[0]
                    if (selectorGroup)selectorGroup.options.push(option)
                    else tempItemGroup.selectorGroups.push({ name: option.selector, options: [option]})

                    optionGroup = tempItemGroup.optionGroups.filter(g => g.name == option.optionGroup)[0]
                    if (optionGroup) optionGroup.options.push(option)
                    else tempItemGroup.optionGroups.push({ name: option.optionGroup, options: [option]})
                    
                }/*else{                    
                    selectorGroup = { name: option.selector, options: [option]}
                    optionGroup = { name: option.optionGroup, options: [option]}
                    
                }*/
                tempItemGroups.itemGroups[option.subCategory] = tempItemGroup
            })
            tempCategoryGroups[oTree.category] = tempItemGroups
        })
        setCategoryGroups(tempCategoryGroups)

    }, [optionTree])

    

    var verslag: string = "@sed-g"
    return (
        <VerslagEditorStyled>
            {categories.map((cat:string) => {
                var itemGroups = categoryGroups[cat]
                return itemGroups ? <HeaderItem key={cat}  itemGroups={itemGroups}  /> : ''
            })}
        </VerslagEditorStyled>
    )
};

export default VerslagEditor;