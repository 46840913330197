import { styled } from '@mui/material/styles';
import { TabList } from '@mui/lab';
import { Tab } from '@mui/material'


const CSSTabList = styled(TabList)({
    "& .MuiButtonBase-root" : {
        //borderTopLeftRadius:'10px',
        //borderTopRightRadius:'10px',
        //boxShadow: '0 0 2px 0px var(--neutral-900)',
        fontFamily: 'Rubik',
        fontSize: '1.1rem'
    },
    "& .MuiTabs-scroller" : {
        padding: '5px 10px 0px 10px',
        maxHeight:'48px',

    },
    "& .MuiButtonBase-root.Mui-selected" : {
        //backgroundColor: 'rgba(0,0,50,0.045)'
        fontWeight:500
    },
    
        borderBottom: '1px solid var(--primary-700)',
        backgroundColor: 'var(--primary-100)',
        paddingBottom: '1px'

    
})

interface IDocTabInfo {
    label:string;
    index:string;
}

interface IDocTabList {
    pages: IDocTabInfo[];
    pageIndex: string;
    setPageIndex: Function;
}

const DocTabList : React.FC<IDocTabList> = ({pages, pageIndex, setPageIndex}) => {

    return (
        <CSSTabList onChange={(event: React.SyntheticEvent, newIndex:string) => {setPageIndex(newIndex)}}>
            {
                pages.map((info, index) => {
                    return <Tab label={info.label} key={info.index} value={info.index.toString()}/>
                })
            }
        </CSSTabList>
    )
};

export default DocTabList;