import React, { useEffect, useState } from 'react';
import FastAPIClient from '../../client';
import config from '../../config';

import DashboardHeader from "../../components/DashboardHeader";
import { Icon } from '@mui/material';
//import Footer from "../../components/Footer";
//import Loader from '../../components/Loader';

const client = new FastAPIClient(config);
//const logo = require('../../assets/logo.svg') 

//import {ReactComponent as Logo} from  '../../assets/logo.svg'
import Logo from '../../components/Logo';


const Home = () => {

     const [loading, setLoading] = useState(true)
     const [recipes, setRecipes] = useState([])
     const [searchValue, setSearchValue] = useState("chicken")

     /*
     useEffect(() => {
          // FETCH THE RECIPIES
          fetchRecipes()
     }, [])


     const fetchRecipes = (search) => {

          if (searchValue?.length <= 0 && search)
               return alert("Please Enter Search Text")

          // SET THE LOADER TO TURE
          setLoading(true)

          // GET THE RECIPIES FROM THE API
          client.getRecipes(searchValue).then((data) => {
               setLoading(false)

               // SET THE RECIPIES DATA
               setRecipes(data?.results)
          });
     }


     if (loading)
          return <Loader />
     */
    /*      
               <Icon  
                    sx={{width: "50%", height: "auto", margin: "auto", fill:"rgba(212, 175, 55, 0.25)"}}
               >
                    <Logo />
               </Icon>
               */
     return (
          <>
               <section className="bg-black" style={{display: "flex"}}>
               
               
               <Logo fillExt='rgba(255,255,255, 0.5)'/>
                    
               </section>
          </>
     )
}

export default Home;