import * as React from 'react'
import {
    Box, MenuItem, Select, FormControl, InputLabel
} from "@mui/material"
import {IDatabase} from "./interfaces"

const DatabaseToolBar : React.FC<IDatabase> = ({database,databases, setDatabase, setDatabases}) => {
    
    return (
        <Box sx={{height:'125px', background:'var(--primary-500)'}} >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 150, float:'right' }}>
                
                <InputLabel id="select-onderzoek-type-label">Database</InputLabel>
                <Select
                    id="database-select"
                    labelId="database-select-label"
                    value={database}
                    onChange={(event) => {
                        setDatabase(event.target.value)
                        
                    }}

                >
                    {databases.map((item,index) => {
                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Box>
    )
}

export default DatabaseToolBar;