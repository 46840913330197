import { FirebaseApp, initializeApp } from "firebase/app";
import { FirebaseStorage, getStorage, ref, StorageReference, getDownloadURL } from 'firebase/storage'
const firebaseConfig = {
    apiKey: "AIzaSyCd0PI8I040XEc2whg7Y0VqbcwvbR09PEM",
    authDomain: "vervaecke-front.firebaseapp.com",
    projectId: "vervaecke-front",
    storageBucket: "vervaecke-front.appspot.com",
    messagingSenderId: "514744399976",
    appId: "1:514744399976:web:c9f11cd49ded1a980d23dd",
    measurementId: "G-HENDKSFHDK"
  };

/*
interface IFirebase{
    app: FirebaseApp;
    storage?: FirebaseStorage;
    images?: StorageReference;
    imageURL: Function
}
const firebaseImageURL = (image:string) => {
    return
}
const firebase:IFirebase = {app: initializeApp(firebaseConfig), imageURL: firebaseImageURL}
firebase.storage = getStorage(firebase.app)
firebase.images = ref(firebase.storage, 'images')
*/
export class Firebase {
    app;
    storage;
    images;
    getDownloadURL = getDownloadURL
    
    constructor(){
        this.app = initializeApp(firebaseConfig)
        this.storage = getStorage(this.app)
        this.images = ref(this.storage, 'images/')
    }

    async imageURL(image:string) {
        var r = ref(this.images, image)
        
        return getDownloadURL(ref(this.storage,'/images/' +  image)).then( (url) => {
            console.log(url)
            return url
        }).catch( () => {
            return ''
        })
        
    }
}
const firebase = new Firebase()
export default firebase
