import * as React from 'react'

import { IPanelViewProps } from './interfaces'
import { IRow } from '../ScreeningPanelView';

import {
    Box, Paper, Typography, Divider
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2';
import {createTheme, ThemeProvider, CssBaseline} from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

const theme = createTheme({
    palette: {
        primary: {
            main: "#00FF00" //'var(--green-primary-500)'
        }
    },
    typography: {
        fontFamily: "Rubik",
        fontWeightRegular: 500,
        //fontSize:24
        caption : {
            fontSize:32,
            padding: '0 0 0 10px',
            color: 'var(--primary-500)'
        }

    },
    
})

interface IScreeningOverdueComponentProps extends IPanelViewProps{
    overdueRows: IRow[];
}
const ScreeningOverdueComponent:React.FC<IScreeningOverdueComponentProps> = (props) => {
        const {overdueRows} = props
    return (
        <ThemeProvider theme = {theme}>
            <CssBaseline />
            <Paper
                style={{width:'100%', minHeight:'50px', padding:'10px'}}
                elevation={4}
            >
                <Grid2 container >
                    <Grid2 xs={12} > <Typography variant="caption" > Overdue Screening</Typography></Grid2>
                    <Grid2 xs={12}> <Divider sx={{borderBottomWidth:3, borderColor:'var(--primary-300)'}}/></Grid2>
                    <Grid2 xs={12}>
                        <TableContainer>
                
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align = {'center'} padding = {'none'}>Naam</TableCell>
                                        <TableCell align = {'center'} padding = {'none'}>Patiënten nummer</TableCell>
                                        <TableCell align = {'center'} padding = {'none'}>Datum Onderzoek</TableCell>
                                        <TableCell align = {'center'} padding = {'none'}>Follow-up Datum</TableCell>
                                        <TableCell align = {'center'} padding = {'none'}>Termijn</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.values(overdueRows).map((row : IRow,index) => {
                                        var termijn = row.vetotermijn != "0" ? row.vetotermijn : row.termijn
                                        return(
                                            <TableRow
                                                //onClick={(event) => {handleRowClick(event, row.id)}}
                                                key={row.id}
                                                //selected={selectedRow == row.id}
                                            >
                                                <TableCell sx={{padding:'8px 16px' }}align = {'center'}>{index + 1}</TableCell>
                                                <TableCell sx={{padding:'8px 16px' }}align = {'left'}>{row.name}</TableCell>
                                                <TableCell sx={{padding:'8px 16px' }}align = {'center'}>{row.ptnr}</TableCell>
                                                <TableCell sx={{padding:'8px 16px' }}align = {'center'}>{row.datum}</TableCell>
                                                <TableCell sx={{padding:'8px 16px' }}align = {'center'}>{row.termijndate}</TableCell>
                                                <TableCell sx={{padding:'8px 16px' }}align = {'center'}>{termijn} maanden</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid2>
                </Grid2>
            </Paper>
        </ThemeProvider>
    )
}

export default ScreeningOverdueComponent;

//