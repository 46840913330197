import * as React from 'react'
import { Icon } from '@mui/material'
import { styled } from '@mui/material/styles'
import LogoSVG from './logo';



const LogoRoot = styled(Icon, {
    name:"Logo",
    slot:"root",
    shouldForwardProp: (prop) => prop !== "fill" && prop !== 'width' && prop !== 'height' && prop !== 'fillSymbol' && prop !== 'fillText' && prop !== 'fillExt'
})<ILogo>((props) => ({
    width: props.width ? props.width : "65%",
    height : props.height ? props.height : "auto",
    margin: "auto",
    display: "flex",
    "svg": {
        fill: props.fill ? props.fill : "rgba(212, 175, 55, 0.25)",
        "& .symbol": {
            fill: props.fillSymbol ? props.fillSymbol : "inherit"
        },
        "& .text": {
            fill: props.fillText ? props.fillText : "inherit"
        },
        "& .ext": {
            fill: props.fillExt ? props.fillExt : "inherit"
        },
        
    },
    "svg:hover" : {
        ...(props.href && {fill: props.fillHover ? props.fillHover :  "var(--space-cadet)"})
    }
}))

interface ILogo {
    fill?: string
    fillSymbol?: string
    fillText?: string
    fillExt?: string
    fillHover?: string
    width?: number
    height?: number
    href?: string
}

const Logo:React.FC<ILogo> = (props) => {
    if (props.href){
        return (
        <a href={props.href}>
            <LogoRoot {...props} >
                <LogoSVG />
            </LogoRoot>
        </a>
        )
    }
    else{
        return (
        <LogoRoot {...props} >
            <LogoSVG />
        </LogoRoot>
        )
    }
        
    
};

export default Logo;