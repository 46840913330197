import * as React from 'react'
import {
    Box, Typography
} from "@mui/material"

import Grid from "../../components/Containers/Grid"
import {IDatabase, IConsoleLine} from "./interfaces"
import {createTheme, ThemeProvider, CssBaseline} from '@mui/material'

import DatabaseInput from './inputbar'


const theme = createTheme({
    palette: {
        success: {
            main: "#00FF00" //'var(--green-primary-500)'
        }
    },
    typography: {
        fontFamily: "inconsolata",
        fontWeightRegular: 500
    },
})


const ConsoleLine : React.FC<IConsoleLine> = ({msg, status, origin, type, row, ...otherProps}) => {
    
    return (
        <>
            <Box sx={{display:'flex', gridRow:row}} className='origin'>{( () => {
                switch (origin){
                    case 'client':
                        return (
                            <Typography color="info.main" sx={{ml:'auto', mr:'5px'}}>{">"}</Typography>
                        )
                    case 'server':
                        return (
                            <>
                            <Typography color="success.main" sx={{ml:'auto', mr:'0px'}}>{"<"}</Typography>
                            <Typography color="info.main" sx={{ml:'auto', mr:'0px'}}>{"SERVER"}</Typography>
                            <Typography color="success.main" sx={{ml:'auto', mr:'5px'}}>{">"}</Typography>
                            </>
                        )
                    case '':
                        return (
                            <Typography color="success.main" sx={{ml:'auto', mr:'5px'}}>{""}</Typography>
                        )
                }
                
            })()}
            </Box>
            {(()=>{
                switch (type){
                    case 'msg':
                        return (<Typography color={status + ".main"} {...otherProps}
                        onClick={event => {
                            if (event.detail == 2){
                                var sel = window.getSelection()
                                var r = document.createRange()
                                r.selectNodeContents(event.target as Node)
                                sel?.removeAllRanges()
                                sel?.addRange(r)
                            }
                       }}
                        >{msg}</Typography>)
                    case 'header':
                        var values = otherProps.values || []
                        var widths = otherProps.widths || []
                        return (
                            <Grid sx={{borderBottom: "2px dashed white", width:"fit-content", gridTemplateColumns:`${widths.join('px ')}px`, gridGap:'10px' }}>
                                {
                                    values.map((v,k) => {
                                        return (
                                        <Typography key={k} color="info.main" sx={{textAlign:'center'}} {...otherProps}
                                            onClick={event => {
                                                 if (event.detail == 2){
                                                     var sel = window.getSelection()
                                                     var r = document.createRange()
                                                     r.selectNodeContents(event.target as Node)
                                                     sel?.removeAllRanges()
                                                     sel?.addRange(r)
                                                 }
                                            }}
                                        >{v}</Typography>)
                                    })
                                }
                            </Grid>
                        )
                    case 'row':
                        var values = otherProps.values || []
                        var widths = otherProps.widths || []
                        return (
                            <Grid sx={{width:"fit-content", gridTemplateColumns:`${widths.join('px ')}px`, gridGap:'10px' }}>
                                {
                                    values.map((v,k) => {
                                        var align: string = Number.isInteger(v) ? 'center' : 'left'
                                        return (
                                            <Typography key={k} color="common.white" sx={{textAlign:align}} {...otherProps}
                                            onClick={event => {
                                                if (event.detail == 2){
                                                     var sel = window.getSelection()
                                                     var r = document.createRange()
                                                     r.selectNodeContents(event.target as Node)
                                                     sel?.removeAllRanges()
                                                     sel?.addRange(r)
                                                 }
                                            }}
                                            >{v}</Typography>
                                        )
                                    })
                                }
                            </Grid>
                        )
                }
            })()}
        </>
    )
}


export interface IConsoleVisible{
    totalRows: number;
    numRows: number;
    topVisible: number;
    bottomVisible: number;
}

const DatabaseConsole : React.FC<IDatabase> = (props:IDatabase) => {
    
    const [originWidth, setOriginWidth] = React.useState<number>(25)
    const [renderRows, setRenderRows] = React.useState<IConsoleLine[]>([])
    const [visibleRange, setVisibleRange] = React.useState<IConsoleVisible>({
        totalRows: 0,
        numRows: 0,
        topVisible: -1,
        bottomVisible: -1
    })

    React.useEffect(() => {
        document.addEventListener('keydown', (event) => {
            if (window.location.pathname.toLocaleLowerCase() != "database") return
            var e = document.querySelectorAll('.InputBar .MuiInputBase-input')[0] as HTMLInputElement
            //console.log(e, event.target, e==event.target)
            if (event.ctrlKey || event.altKey || event.shiftKey) return;
            if (e != event.target){
                e.focus()

            }
        })
        document.addEventListener('mouseup', (event) => {
            var e = document.querySelectorAll('.console-window')[0]
            if (window.location.pathname.toLocaleLowerCase() != "database") return
            if (e.contains(event.target as Node) && event.target as Node != document.querySelectorAll('.InputBar .MuiInputBase-input')[0] as HTMLInputElement){
                var text = document.getSelection()?.toString()
                if (text && text.length > 0){
                    navigator.clipboard.writeText(text)
                    document.getSelection()?.removeAllRanges()
                    
                }
                var i = document.querySelectorAll('.InputBar .MuiInputBase-input')[0] as HTMLInputElement
                i.focus()
            }
        })
        return;
        /*
        var console = document.getElementsByClassName('console-window')[0]
        console.addEventListener('wheel', (event:WheelEvent) => {
            if (event.deltaY > 0){ // down
                
            }else{ // up
                
            }
            debugger
            return
            var consoleHeight: number = console.clientHeight;
            var visibleRows: number = Math.ceil(consoleHeight / 25) + 5
            var fromTop: number = Math.floor(console.scrollTop / 25)
            setRenderRows(props.consoleLines.slice(fromTop, fromTop + visibleRows))
        })
        */
    }, [])
    

    React.useEffect(() => {
        var max:number = 0
        props.consoleLines.forEach(v => {
            if (!v.origin) return
            switch (v.origin){
                case 'client':
                    max = max > 25 ? max : 25
                    break;
                default:
                    max = max > 2*10 + v.origin.length * 8 ? max : 2*10 + v.origin.length * 8 + 10
            }
        })
        setOriginWidth (max)
        var Console = document.getElementsByClassName('console-window')[0]
        if (!Console) return;
        var consoleHeight: number = Console.clientHeight - 45;
        var visibleRows: number = Math.ceil(consoleHeight / 25)
        var fromTop: number = Math.floor(Console.scrollTop / 25)
        var range: IConsoleVisible = {
            totalRows: props.consoleLines.length,  
            numRows: visibleRows, 
            topVisible: Math.max(0, props.consoleLines.length - visibleRows), 
            bottomVisible: props.consoleLines.length
        }
        
        setVisibleRange(range)
        setRenderRows(props.consoleLines.slice(range.topVisible,range.bottomVisible))
        //setRenderRows(props.consoleLines.slice(fromTop, fromTop + visibleRows))
        //console.scrollHeight = 25 * props.consoleLines.length

    }, [props.consoleLines])

    const onConsoleScroll = (event: React.WheelEvent) => {
            //event.preventDefault()
            var Console = document.getElementsByClassName('console-window')[0]
            var consoleHeight: number = Console.clientHeight - 45;
            var visibleRows: number = Math.min(Math.ceil(consoleHeight / 25) , visibleRange.numRows)
            var range = { ...visibleRange,
                ...{numRows: visibleRange.numRows}
            }
            
            if (event.deltaY < 0){ // up
                if (visibleRange.topVisible <=0 || visibleRange.totalRows <= visibleRows) return
                var deltaRows = Math.abs(Math.ceil(event.deltaY / 25)) + 5
                range.topVisible = Math.max(0, range.topVisible - deltaRows)
                range.bottomVisible = Math.max(visibleRows, range.bottomVisible - deltaRows)
                setVisibleRange(range)
                setRenderRows(props.consoleLines.slice(range.topVisible,range.bottomVisible))
                
            }else{ // down
                if (visibleRange.bottomVisible >= visibleRange.totalRows || visibleRange.totalRows <= visibleRows) return
                var deltaRows = Math.abs(Math.ceil(event.deltaY / 25)) + 5
                range.topVisible = Math.min(range.totalRows - visibleRows, range.topVisible + deltaRows)
                range.bottomVisible = Math.min(range.totalRows, range.bottomVisible + deltaRows)
                setVisibleRange(range)
                setRenderRows(props.consoleLines.slice(range.topVisible,range.bottomVisible))
                //debugger
                if (visibleRange.bottomVisible <= visibleRows){
                    
                }
            }
            //debugger
        
    }
    return (
        <ThemeProvider theme = {theme}>
            <CssBaseline />
            <Box sx={{height:'100%',background:'var(--primary-900)', overflow:'auto', padding:'0px 10px 0px 0px'}} 
            className='console-window' 
            onWheel = {onConsoleScroll}
                
            >
                <Grid sx={{ gridTemplateColumns:originWidth + 'px auto', gridTemplateRows:'fit-content', pl:'5px'}}>
                    {//!props.consoleLines ? '' : props.consoleLines.map((v,k) => {
                    renderRows.length == 0 ? '' : renderRows.map((v,k) => {
                        
                        return (
                            <ConsoleLine key={k} row={k+1} {...v}/>
                            
                        )
                    })}
                </Grid>
                <DatabaseInput  {...props}/>
            </Box>
        </ThemeProvider>
    )
}

export default DatabaseConsole;