import * as React from 'react';
import { Firebase } from '../../../../Firebase';
import {
    Typography
} from '@mui/material'

import { styled } from '@mui/material/styles'

const TitleTypography = styled(Typography)({
    textAlign:'center',
    fontSize:'0.9rem'
})

export const parseIMG = (line:String, firebase: Firebase) => {
    console.log(line)
    line = line.replace(/\*img\s/i, '')

    const [imageURL, setImageURL] = React.useState<string>('')

    const props = {}
    
    const title = line.match(/-title=["']([^"']+)["']/i)
    const titleBot = line.match(/-title=["']([^"']+)["']\sbottom/i)
    const titleTop = line.match(/-title=["']([^"']+)["']\stop/i)
    const titleBoth = line.match(/-title=["']([^"']+)["'](\s+both|\s+-|\s*$)/i)
    const src = line.match(/-src=["']([^"']+)["']/i)
    const style: any = {}
    if (line.match(/-border\s*$/i)) style.border= '1px solid black'
    
    firebase.imageURL(src ? src[1] : '').then((url) => {
        setImageURL(url)
    })
    
    return (
        <React.Fragment>
            {title && (titleTop || titleBoth) &&  <TitleTypography >{title[1]}</TitleTypography>}
            <img
                src={imageURL}
                style={style}
            >
            </img>
            {title && (titleBot || titleBoth) &&  <TitleTypography >{title[1]}</TitleTypography>}
        </React.Fragment>
    )
}