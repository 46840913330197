import * as React from 'react'
import { styled } from '@mui/material/styles'
import { ToggleButtonGroup, ToggleButton} from '@mui/material'
import { Paper, Divider, FormLabel, Box } from '@mui/material'
import { IVerslagItemGroup, IVerslagOption, IVerslagOptionTree, IVerslagOptionGroup } from '../interfaces'

const ToggleButtonGroupStyled = styled(ToggleButtonGroup, {})(({}) => ({
    padding:"0px 15px",
    marginBlock: "5px",
    '& .MuiToggleButtonGroup-grouped':{
        border:0
    }
}))
const ToggleButtonStyled = styled(ToggleButton, {})(({}) => ({
    "&.Mui-selected" : {
        background:'#1976d2',
        color:"var(--front-100)",
        
    },
    "&.Mui-selected:hover" : {
        color: "var(--primary-600)"
    }
}))
const PaperStyled = styled(Paper, {})(({}) => ({
    padding: "5px 20px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "5px 10px"
    
}))
const DividerStyled = styled(Divider, {})(({}) => ({
    
}))
const FormLabelStyled = styled(FormLabel, {})(({}) => ({
    flex: "100%"
}))

interface IVerslagOptionToggleGroup {
    itemGroup: IVerslagItemGroup//IVerslagOptionGroup
    //optionTree: IVerslagOptionTree
    options:IVerslagOption[]
}



const VerslagOptionToggleGroup : React.FC<IVerslagOptionToggleGroup> = ({itemGroup, options}) => {
    const [optionGroups, setOptionGroups] = React.useState<IVerslagOptionGroup[]>([])
    const [optionGroupCategories, setOptionGroupCategories] = React.useState<string[]>([])

    return (
        <React.Fragment>
        {
            optionGroupCategories.map(category => {
                return (
                    <PaperStyled>
                        {
                            optionGroups.filter(optionGroup => optionGroup.name == category).map((optionGroup, index) => {
                                if (index == optionGroups.length - 1){
                                    return(
                                        <ToggleButtonGroupStyled>
                                            {
                                                optionGroup.options.map(option => {
                                                    return <ToggleButtonStyled key={option.id} value={option.id}>{option.name}</ToggleButtonStyled>
                                                })
                                            }
                                        </ToggleButtonGroupStyled>
                                    ) 
                                }else{
                                    return (
                                        <ToggleButtonGroupStyled>
                                            {
                                                optionGroup.options.map(option => {
                                                    return <ToggleButtonStyled key={option.id} value={option.id}></ToggleButtonStyled>
                                                })
                                            }
                                            <DividerStyled />
                                        </ToggleButtonGroupStyled>
                                        
                                    )
                                }
                            })
                        }
                    </PaperStyled>
                )
            })
        }
        </React.Fragment>
    )
    
};
interface IVerslagItem {
    //option: IVerslagOption
    //optionTree: IVerslagOptionTree
    itemGroup: IVerslagItemGroup
    setRenderGroups : Function
}
const VerslagItem:React.FC<IVerslagItem> = ({itemGroup, setRenderGroups}) => {
    const [nextItem, setNextItem] = React.useState<boolean>(false)
    const [optionGroups, setOptionGroups] = React.useState<string[]>([])
    //const [selectorGroups, setSelectorGroups] = React.useState<IVerslagOption[]>([])
    const [renderOptions, setRenderOptions] = React.useState<IVerslagOption[][]>([])
    const [initialOption, setInitialOption] = React.useState<IVerslagOption>()
    const [values, setValues] = React.useState<IVerslagOption[]>([])
    
    React.useEffect(() => {
        setInitialOption(itemGroup.options.find(o => o.selector == itemGroup.initialItem[0]))
    }, [])

    React.useEffect(() => {
        if (initialOption == undefined) return
        var initialSelector: string = initialOption.selector
        var options = itemGroup.selectorGroups.find(g => g.name == initialSelector)?.options
        if (options){
            setRenderOptions([options])
            //setValues([options.find(o => o.default)?.id as number])
            setValues([options.find(o => o.default) as IVerslagOption])
        }
    }, [initialOption])
    
    const handleChange = (value: IVerslagOption[], index:number) => {
        if (value.length < 2)return
        console.log(value, index)
        var [prev,next] = value
        var previous: IVerslagOption[] = [...values]
        previous[index] = next
        
        
        var options: IVerslagOption[] = []
        var _renderOptions: IVerslagOption[][] = [...renderOptions]
        if (prev.options.length !== next.options.length || !prev.options.every((value, index) => value === next.options[index])){
            if (prev.options.length > 0){
                previous = previous.slice(0, index+1)
                _renderOptions = _renderOptions.slice(0, index+1)
            }
            //debugger
            while (next.options.length > 0){
                options = []
                itemGroup.selectorGroups.filter(g => next.options.indexOf(g.name) != -1).forEach(group => {
                    options = [...options, ...group.options]
                })
                next = options.find(o => o.default)||options[0]
                previous.push(next)
                _renderOptions.push(options)
            }
        }
        
        setValues(previous)
        setRenderOptions(_renderOptions)
    }
    React.useEffect(() => {
        if (values.length == 0)return
        setRenderGroups((state:{[key:string]:boolean}) => {
            var temp = {
                ...state,
                [itemGroup.nextItem]: values[values.length-1].next_option != 'end' || values[values.length-1].next_option.length == 0
            }
            console.log(temp)
            return temp
        })
    }, [values])
    React.useEffect(() => {
        console.log('RenderOptions:',renderOptions, itemGroup)
    }, [renderOptions])
    return (
        <PaperStyled elevation={3}>
            <FormLabelStyled>{itemGroup.label}</FormLabelStyled>
            {
                renderOptions.map((optionGroup, index) => {
                    
                    return (
                        <Box key={index} sx={{display:"flex", flexWrap:"wrap"}}>
                            <ToggleButtonGroupStyled size="small" aria-label={`group-${index}`} value={[values[index]]} onChange={(e,v) => handleChange(v,index)}>
                                {optionGroup.map(option => {
                                    return <ToggleButtonStyled key={option.id} value={option}>{option.name}</ToggleButtonStyled>
                                })}
                            </ToggleButtonGroupStyled >
                            {index < renderOptions.length -1 && renderOptions.length > 1? <DividerStyled flexItem orientation="vertical" sx={{mx: 0.5, my: 1}}/> :''}
                        </Box>
                    )
                })
            }
            
            
        </PaperStyled>
    )
}
export default VerslagItem