import * as React from 'react'
import {
    Drawer, Button,
    List, ListItem, ListItemButton,
    ListItemIcon, ListItemText,
    Collapse
} from '@mui/material'
import { StaticDatePicker } from '@mui/lab';
import { styled } from '@mui/material/styles';
import DehazeIcon from '@mui/icons-material/Dehaze';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CodeIcon from '@mui/icons-material/Code';
import HtmlIcon from '@mui/icons-material/Html';

import { IFiletreeItem, IDocDrawer } from '../interfaces/interfaces';



const CSSButton = styled(Button)({
    width: '50px',
    height: '50px',
    position: 'absolute',
    top: 0,
    left: 0,
    color: 'var(--primary-100)',
    "& svg" : {
        width: '50px',
        height: '50px'
    }
});

const CSSDrawer = styled(Drawer)({
    "& .MuiPaper-root": {
        minWidth:'250px',
    },
    
    "& .MuiListItemIcon-root": {
        minWidth:'25px', marginRight:'5px'
    },
    "& .MuiSvgIcon-root": {
        minWidth: '25px', marginRight:'5px'
    },
    "& .MuiTypography-root":{
        fontSize: '1.25rem'
    }
})


interface IMenuItem {
    item: IFiletreeItem;
    filetree: IFiletreeItem[];
    level:number;
    setSelectedItem:Function;
}
const MenuItem:React.FC<IMenuItem> = ({item, filetree, level, setSelectedItem}) => {
    switch(item.type){
        case 'markup':
            return (
                <ListItem 
                    button 
                    sx={{paddingLeft: (25 * level) + 'px'}}
                    onClick={() => {setSelectedItem(item.id)}}
                >
                    <ListItemIcon > <CodeIcon /></ListItemIcon>
                    <ListItemText primary={item.label}/>
                </ListItem>
            )
        case 'menuitem':
            if (item.children == null){
                return (
                    <ListItem button sx={{paddingLeft: (25 * level) + 'px'}}>
                        <ListItemIcon />
                        <ListItemText primary={item.label}/>
                    </ListItem>
                )
            }
            else{
                const [state, setState] = React.useState<boolean>(false)
    
            
                return (
                    <React.Fragment >
                        <ListItem button onClick={() => {setState((prev) => !prev)}}  sx={{paddingLeft: (25 * level) + 'px'}}>
                        {state ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                            <ListItemText primary={item.label}/>
                        </ListItem>
                        <Collapse in={state} timeout="auto" unmountOnExit>
                            <List component="div" >
                                {
                                    item.children.map((childId, key) => {
                                        return <MenuItem 
                                            key={key} 
                                            item={filetree[childId - 1]} 
                                            filetree={filetree} 
                                            level={level + 1}
                                            setSelectedItem={setSelectedItem}
                                        />;
                                    })
                                }
                            </List>
                        </Collapse>
                    </React.Fragment>
                )
            }
        default:
            return (<></>)
    }
    
};



const DocDrawer : React.FC<IDocDrawer> = ({filetree, setSelectedItem}) => {
    const [state, setState] = React.useState<boolean>(false)

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        setState(open)
    };
    
    return (
        <React.Fragment>
            <CSSButton 
                onClick={toggleDrawer(true)}
            >
                <DehazeIcon />
            </CSSButton>
            <CSSDrawer open={state} anchor='left' onClose={toggleDrawer(false)}>
                {
                    filetree.map((item, key) => {
                        
                        if (item.parent == null) {
                            return (
                                <MenuItem 
                                    key={key} 
                                    item={item} 
                                    filetree={filetree} 
                                    level={1}
                                    setSelectedItem={setSelectedItem}
                                />
                            )
                        }
                        else { return ''}
                    })
                }
            </CSSDrawer>
        </React.Fragment>
    )
};

export default DocDrawer;