import * as React from 'react'
//import {styled} from '@mui/material/styles'
import { SxProps, unstable_styleFunctionSx, styled, ThemeProvider, createTheme} from '@mui/system';
//import {createTheme} from '@mui/material/styles'
//import styled, {InterpolationFunction} from 'styled-components'


const  options = {
    shouldForwardProp: (prop:string) => prop !== 'gridTemplateRows' && prop !== 'sx',
    name: 'MyGrid',
    slot: 'Root',
    overridesResolver: (props:any, styles:any) => [
        styles.root,
        props.color === 'primary' && styles.primary,
        props.color === 'secondary' && styles.secondary,
      ],
}

const theme = createTheme({
    components: {
        MyGrid: {
            styleOverrides: {
                root: {
                    display: 'grid',
                    backgroundColor: 'red'
                }
            }
        }
    }
    
})

const MyGrid = styled('div', options)(({theme}) => ({
    display: 'grid'
}))

type GridProps = {
    children: React.ReactNode;
    sx?: SxProps
}

const Grid : React.FC<GridProps> = (props: GridProps) => {
    const {...other} = props
    return (
        <MyGrid {...other}></MyGrid>
        
    )
}

export default Grid;