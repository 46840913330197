import * as React from 'react';
import {
    Box, Paper
} from '@mui/material'

import Select from '../../Inputs/Select';
import { Variant } from '@mui/material/styles/createTypography';

export interface ScreeningViewToolbarProps{
    onderzoekTypes: string[];
    onderzoekTypeSelected: "" | HTMLInputElement | undefined;
    setOnderzoekTypeSelected?: Function;
    upcomingTerms: string[];
    upcomingTerm: "" | HTMLInputElement | undefined;
    setUpcomingTerm: Function
}

const ScreeningViewToolbar: React.FC <ScreeningViewToolbarProps> = ({...props}) => {
    const {
        onderzoekTypes, onderzoekTypeSelected, setOnderzoekTypeSelected,
        upcomingTerms, upcomingTerm, setUpcomingTerm
    } = props
    const onderzoekSelectProps = {
        values:onderzoekTypes, 
        value:onderzoekTypeSelected, 
        valueFunc:setOnderzoekTypeSelected,
        label:"Type onderzoek",
        id:"select-onderzoek-type",
        variant:'standard' as const,
        sx : {width:'-webkit-fill-available', m:1}
    }
    const upcomingtermSelectProps = {
        values: upcomingTerms, 
        value: upcomingTerm, 
        valueFunc:setUpcomingTerm,
        label:"Zoek Termijn",
        id:"select-termijn-duur",
        variant:'standard' as const,
        sx : {width:'-webkit-fill-available', m:1}
    }
    return(
        <Box>
            <Paper sx={{width:'100%', height:'100%', backgroundColor:'var(--primary-300)'}}>
                <Select {...onderzoekSelectProps}/>
                <Select {...upcomingtermSelectProps}/>
            </Paper>
        </Box>
    )
};

export default ScreeningViewToolbar;