import * as React from 'react'
import {
    Container
} from '@mui/material'
//import {Unstable_Grid2 as Grid} from '@mui/material'
import Grid from '../../components/Containers/Grid'
import DatabaseToolBar from './toolbar'
import DatabaseInput from './inputbar'
import DatabaseConsole from './console'
import { IDatabase, IConsoleLine, ConsoleLine } from './interfaces'

import FastAPIClient from '../../client';
import config from '../../config';
const client = new FastAPIClient(config);

interface DataBaseResponseItem{
    type:string;
    status:string;
    content:any;
}
interface DatabaseResponse{
    data: DataBaseResponseItem
}

const DatabasePage: React.FC = () => {
    const [initialised, setInitialised] = React.useState<boolean>(false)
    const [inputCMD, setInputCMD] = React.useState<string>('')
    const [consoleLines, setConsoleLines] = React.useState<IConsoleLine[]>([])
    const [status, setStatus] = React.useState<number>(0)
    const [queryLines, setQueryLines] = React.useState<IConsoleLine[]>([])
    const [database, setDatabase] = React.useState<string>('')
    const [databases, setDatabases] = React.useState<string[]>([])
    const [inputLines, setInputLines] = React.useState<string[]>([])
    const [inputLine, addInputLine] = React.useState<string>('')
    
    

    const props = {
        inputCMD: inputCMD,
        setInputCMD: setInputCMD,
        consoleLines: consoleLines,
        setConsoleLines: setConsoleLines,
        database: database,
        databases:databases,
        setDatabase:setDatabase,
        setDatabases:setDatabases,
        inputLines:inputLines,
        setInputLines:setInputLines,
        addInputLine:addInputLine
    }

    React.useEffect(() => {
        client.database_list().then((response:any) => {
            setDatabases(response.data.content.rows)
            const db = window.localStorage.getItem('database_selected') as string
            if (response.data.content.rows.includes(db)) setDatabase(db)
            else setDatabase(response.data.content.rows[0])
            
        })
    }, [])
    React.useEffect(() => {
        
        const cl = window.localStorage.getItem('consoleLines') as string
        if (cl != null){
            setConsoleLines(JSON.parse(cl))  
        }
        setInputCMD('')
        const il = window.localStorage.getItem('inputLines') as string
        if (il != null){
            setInputLines(JSON.parse(il))  
        }
        addInputLine('')
        
        
        setInitialised(true)
    }, [])

    React.useEffect(() => {
        if (!initialised) return
        const db = window.localStorage.getItem('database_selected')
        if (db != database && database != ''){
            window.localStorage.setItem('database_selected', database)
            setQueryLines([ConsoleLine({
                msg:`Database < ${database} > selected.`,
                status: "success",
                origin: 'server' 
            })])
        }
    }, [database])
    React.useEffect(() => {
        if (queryLines.length == 0) return
        
        setConsoleLines([...consoleLines, ...queryLines])
        setQueryLines([])
    }, [queryLines])
    React.useEffect(() => {
        if (!initialised) return
        if (inputLine.length == 0)return
        const iL = inputLines.slice()
        if (iL.includes(inputLine)) {
            iL.splice(iL.indexOf(inputLine),1)
        }
        if (iL.length >=30){
            setInputLines(iL.slice(1).concat([inputLine]))
        }else{
            setInputLines(iL.slice(0).concat(inputLine))
        }
        addInputLine('')
    }, [inputLine])
    React.useEffect(() => {
        if (!initialised) return
        window.localStorage.setItem('inputLines', inputLines.length > 0 ? JSON.stringify(inputLines): '[]')
    }, [inputLines])
    React.useEffect(() => {
        
        if (!initialised) return
        if (inputCMD ==='' || inputCMD.length == 0) return
        addInputLine(inputCMD)
        switch (inputCMD.toLowerCase()){
            case 'clear':
                setConsoleLines([])
                break;
            case String(inputCMD.match(/(?:list|show) database[s]{0,1};{0,1}/gmi)).toLowerCase():
                setConsoleLines([...consoleLines,ConsoleLine({msg:inputCMD})])
                client.database_list().then((data:DatabaseResponse) => {
                    var rows: IConsoleLine[] = [];
                    var widths: number[] = []
                    data.data.content.columns.forEach((v:string, k:number) => {
                        //if (!widths[k+1]) widths.push(0);
                        widths.push(v.length * 8)
                        
                    })
                    data.data.content.rows.forEach((v:string[], k:number) => {
                        if (typeof(v) === 'string'){
                            
                        }else{
                            v.forEach((a,b) => {
                                if (a != null && a.length * 8 >  widths[b]) widths[b] = a.length * 8;
                            })
                        }
                        
                    })
                    rows.push( ConsoleLine({
                        msg:'',
                        status: data.data.status,
                        origin: 'row',
                        type:'header',
                        widths:widths,
                        values:data.data.content.columns
                    }))
                    data.data.content.rows.forEach((v:string[], k:number) => {
                        rows.push( ConsoleLine({
                            msg:'',
                            status: data.data.status,
                            origin: 'row',
                            type:'row',
                            widths:widths,
                            values: typeof(v) === 'string' ? [v] : v
                        }))
                        
                    })
                    setQueryLines(rows)
                })
                break;
            case String(inputCMD.match(/switch database ([\S]*[^;]);{0,1}/gmi)).toLowerCase():
                setConsoleLines([...consoleLines,ConsoleLine({msg:inputCMD})])
                var db = inputCMD.match(/switch database ([\S]*[^;]);{0,1}/mi)?.[1]
                client.database_switch(db).then((data:DatabaseResponse) => {
                    if (data.data.status == "success"){
                        setQueryLines([ConsoleLine({
                            msg:`Database < ${db} > selected.`,
                            status: data.data.status,
                            origin: 'server'
                        })])
                        setDatabase(db as string)
                        
                    }else{
                        setQueryLines([ConsoleLine({
                            msg:`Database < ${db} > not found.`,
                            status: data.data.status,
                            origin: 'server'
                        })])
                    }
                })
                break;
            case String(inputCMD.match(/hash ([a-zA-Z0-9\s]*[^;]);{0,1}/gmi)).toLowerCase():
                setConsoleLines([...consoleLines,ConsoleLine({msg:inputCMD})])
                var hash = inputCMD.match(/hash ([a-zA-Z0-9\s]*[^;]);{0,1}/mi)?.[1]
                client.database_hash(hash).then((data:DatabaseResponse) => {
                    if (data.data.status == "success"){
                        setQueryLines([ConsoleLine({
                            msg:data.data.content,
                            status: data.data.status,
                            origin: 'server'
                        })])
                        
                        
                    }
                })
                break;
            default:
                setConsoleLines([...consoleLines,ConsoleLine({msg:inputCMD})])
                client.database_query(database, inputCMD).then((data:DatabaseResponse) => {
                    console.log(data)
                    if (data.data.type == 'single'){
                        setQueryLines([ConsoleLine({
                            msg:data.data.content,
                            status: data.data.status,
                            origin: 'server'
                        })])
                    }else if (data.data.type == 'dict'){
                        var rows: IConsoleLine[] = [];
                        var widths: number[] = []
                        data.data.content.columns.forEach((v:string, k:number) => {
                            //if (!widths[k+1]) widths.push(0);
                            widths.push(v.length * 8)
                            
                        })
                        data.data.content.rows.forEach((v:string[], k:number) => {
                            if (typeof(v) === 'string'){
                                
                            }else{
                                v.forEach((a,b) => {
                                    if (a != null && a.length * 8 >  widths[b]) widths[b] = a.length * 8;
                                })
                            }
                            
                        })
                        rows.push( ConsoleLine({
                            msg:'',
                            status: data.data.status,
                            origin: 'row',
                            type:'header',
                            widths:widths,
                            values:data.data.content.columns
                        }))
                        data.data.content.rows.forEach((v:string[], k:number) => {
                            rows.push( ConsoleLine({
                                msg:'',
                                status: data.data.status,
                                origin: 'row',
                                type:'row',
                                widths:widths,
                                values:typeof(v) === 'string' ? [v] : v
                            }))
                            
                        })
                        setQueryLines(rows)



                        
                    }
                })
                break;
        }
        setInputCMD('') 
        
    }, [inputCMD])

    React.useEffect(() => {
        
        if (!initialised) return
        window.localStorage.setItem('consoleLines', consoleLines.length > 0 ? JSON.stringify(consoleLines): '[]')
        setTimeout(() => {
            var e = document.getElementsByClassName('origin MuiBox-root')
            
            if (e.length > 0) e[e.length-1].scrollIntoView()
        }, 100)
    }, [consoleLines])

    return (
        <Container maxWidth={false}>
            <Grid sx={{gridTemplateColumns: '1fr', gridTemplateRows: '125px auto', gridGap:'10px', height:'calc(100vh - 70px)', fontFamily:'inconsolata'}}>
                <DatabaseToolBar {...props}/>
                <DatabaseConsole {...props}/>
                
            </Grid>
        </Container>
    )
}

export default DatabasePage;