import * as React from 'react'
import DocDrawer from './DocDrawer';
import DocCSSPanel from './DocCSSPanel';
import DocMarkup from './DocMarkup/DocMarkup';
import { Paper } from "@mui/material"

import { IDocViewPanel } from '../interfaces/interfaces';

const DocItemType: React.FC<{item:any, firebase:any}> = ({item, firebase}) => {
    //if (!type) return <></>
    
    switch (item.type){
        case "markup":
            return (
                <DocMarkup selectedItemInfo={item} firebase={firebase} />
            )
        default:
            return <React.Fragment></React.Fragment>
    }
    
}

const DocViewPanel : React.FC<IDocViewPanel> = ({value, filetree, ...props}) => {
    
    return (
        <DocCSSPanel value={value}>
            <DocDrawer filetree={filetree} {...props}  />
            <Paper sx={{padding:'0px', width:'fit-content', margin:'auto'}}>
                <DocItemType  item = {props.selectedItemInfo || ''} {...props}/>
            </Paper>
        </DocCSSPanel>
    )
};

export default DocViewPanel;