import * as React from 'react';
import {
    Box, Paper
} from '@mui/material'

import ScreeningOverdueComponent from './ScreeningOverdue'
import { IRow } from '../ScreeningPanelView';

interface IScreeningOverdueContainerProps {
    overdueRows: IRow[];
    upcomingRows: IRow[];
}

const ScreeningViewContainer: React.FC<IScreeningOverdueContainerProps> = (props) => {
    const {
        overdueRows, upcomingRows
    } = props
    return(
        <Box>
            <Paper sx={{width:'100%', height:'100%', backgroundColor:'var(--neutral-300)', padding:'10px'}}>
                <ScreeningOverdueComponent overdueRows={overdueRows} />
            </Paper>
        </Box>
    )
};

export default ScreeningViewContainer;