import * as React from 'react'
import {
    Box, TextField, TextFieldProps, FormControl
} from "@mui/material"
import { styled } from '@mui/material/styles';
import {IDatabase, ConsoleLine} from './interfaces'

const CssTextField = styled(TextField)({
    margin: '0px',
    '& .MuiInputBase-input': {
        caretColor:'var(--accent1-900)',
        lineHeight:'2rem',
        color:'var(--green-primary-500)',
        fontWeight:500,
        fontFamily:"'inconsolata'",
        paddingTop:'5px',
        paddingLeft:'15px'


    },'& .MuiInputBase-root': {
        height:'2rem',
        background:'none'
    },
    '& .MuiInputBase-root:before': {
        content:"'>'",
        position:'absolute',
        bottom:3,
        left:2,
        color:'var(--green-primary-500)',
        fontFamily:"'inconsolata'",
        fontWeight:700,
        fontSize:"1rem"
    },
    '& .MuiInputBase-root:after': {
        borderBottom: '2px solid var(--green-primary-500)',
        
    },
    /*
    '& label.Mui-focused': {
      color: 'var(--primary-900)',
      fontWeight: 400,
    },
    '& .MuiInputBase-input': {
      borderColor: '#c8d0d4',
      backgroundColor: 'var(--primary-200)',
    },
    '& .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
        
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#d32f2f',
        },
      },
      '& fieldset': {
        borderColor: 'var(--neutral-900)',
        //backgroundColor: 'var(--primary-200)',
      },
      '&:hover fieldset': {
        border: '2px solid var(--primary-900)',
        
      },
      '&.Mui-focused fieldset': {
        border: '1px solid var(--primary-900)',
        
      },
    },
    */
  });

type TextInputProps = {
    name: string;
    
  } & TextFieldProps;

const TextInput: React.FC<TextInputProps> = ({name, ...otherProps}) => {

    return (
        <FormControl sx={{padding:'0px'}}>
            <CssTextField
                {...otherProps}
                variant='filled'
                
            />            
        </FormControl>
    )
}



const DatabaseInput:React.FC<IDatabase> = (props:IDatabase) => {
    const {status, addInputLine, inputLines} = {...props}
    const [lineNumber, setLineNumber] = React.useState<number>(0)

    React.useEffect(() => {
      setLineNumber(0)
    }, [])

    const onKeyDown = (event:React.KeyboardEvent<HTMLInputElement>) => {
      
        if (status) return
        
        if (event.key == 'Enter'){
            event.preventDefault()
            const target = event.target as HTMLInputElement
            if (target.value.length != 0){
                
                props.setInputCMD(target.value)
                //props.setConsoleLines([...props.consoleLines,ConsoleLine({msg:target.value})])
                target.value = ''
            }
            setLineNumber(0)
        }
        else if (event.key == 'ArrowUp'){
            event.preventDefault()
            if (lineNumber >= inputLines.length) return
            var inputBar = event.target as HTMLInputElement
            inputBar.value = inputLines[inputLines.length - (lineNumber + 1)]
            inputBar.focus()
            setLineNumber(lineNumber + 1)
        }
        else if (event.key == 'ArrowDown'){
            
            event.preventDefault()
            if (lineNumber <= 0) return
            var inputBar = event.target as HTMLInputElement
            if (lineNumber == 1) {
               inputBar.value = ""
               inputBar.focus()
               setLineNumber(lineNumber - 1)
               return
            }
            inputBar.value = inputLines[inputLines.length - (lineNumber - 1)]
            inputBar.focus()
            setLineNumber(lineNumber - 1)

        }
        //(event:React.ChangeEvent<HTMLInputElement>) => props.setCMD(event.target.value)
    }
    return (
        <FormControl sx={{height:'fit-content', backgroundColor:'var(--primary-900)', marginBottom:'45px', width:'100%'}}>
            <CssTextField fullWidth variant='filled' size='small' onKeyDown={onKeyDown} className='InputBar' autoComplete='off'/>
        </FormControl>
    )
}

export default DatabaseInput