import * as React from 'react'
import { Tab, Box } from '@mui/material';
import { TabPanel } from '@mui/lab' 
import Grid from '../Containers/Grid';

import VerslagEditor from './VerslagEditor';
import VerslagViewer from './VerslagViewer';
import VerslagToolbar from './VerslagToolbar';

import FastAPIClient from "../../client";
import config from "../../config";
import { IVerslagOption } from './interfaces';

export class Client extends FastAPIClient{
    get_option_tree(option:string){
        const form_data = new FormData()
        form_data.append("category", option)
        return this.apiClient.post('/verslag/options/gettree', form_data)
    }
};

const client = new Client(config);


interface IVerslagPanel {
    value:string;
}
const VerslagPanel: React.FC<IVerslagPanel> = ({value}, props) => {

    
    return (
        <TabPanel value={value} sx={{height:'calc(100% - 49px)', padding:'5px'}}>
            <Grid sx={{gridTemplateRows:'50px 1fr', gridTemplateColumns:'auto 750px', gridGap:'5px', height:'100%', width:'100%'}}>
                <VerslagToolbar />
                <VerslagEditor client={client}/>
            </Grid>
        </TabPanel>
    )
};

export default VerslagPanel;