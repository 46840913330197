import * as React from 'react'

import firebase from '../../../Firebase'


import {Box} from '@mui/material';
import { TabContext } from '@mui/lab';
import DocTabList from './DocCSSTabList';

import { IDocEditPanel, IDocViewPanel, IDocTabs, IDocItemInfo } from '../interfaces/interfaces';
import DocViewPanel from './DocViewPanel';
import DocEditPanel from './DocEditPanel';
import { AxiosAdapter, AxiosResponse } from 'axios';


const DocTabs : React.FC<IDocTabs> = ({filetree, ...props}) => {

    const [selectedItem, setSelectedItem] = React.useState<number>()
    const [selectedItemInfo, setSelectedItemInfo] = React.useState<IDocItemInfo>()

    const _props = {firebase, selectedItem, setSelectedItem, selectedItemInfo, setSelectedItemInfo,...props}

    const viewPanelProps: IDocViewPanel = {value:'0', filetree: filetree, ..._props}
    const editPanelProps: IDocEditPanel = {value:'1'}
    
    const pages = [
        {label: "View", index: viewPanelProps.value, props: viewPanelProps, panel: DocViewPanel},
        {label: "Edit", index: editPanelProps.value, props: editPanelProps, panel: DocEditPanel}
    ]
    
    const [pageIndex, setPageIndex] = React.useState<string>('0')

    React.useEffect(() => {
        if (!selectedItem) return;
        props.client.get_doc_by_id(selectedItem).then((response:AxiosResponse) => {
            setSelectedItemInfo(response.data)
            setSelectedItem(undefined)
        })
    }, [selectedItem])
    
    return (
        <Box sx = {{display:'flex', flexDirection:'column'}}>
            <TabContext value={pageIndex}>
                <DocTabList {...{pages, pageIndex, setPageIndex}}/>
            
                <DocViewPanel {...viewPanelProps}/>
                <DocEditPanel {...editPanelProps}/>
            
            </TabContext>
        </Box>
    )
}

export default DocTabs;