import { useNavigate } from "react-router-dom"
export const calcAge = (dob, date) => {
    dob = typeof(dob) === 'string' ? new Date(dob) : dob
    date = typeof(date) === 'string' ? new Date(date) : date

    const ms = date.getTime() - dob.getTime()
    const age = new Date(ms)

    return Math.abs(age.getFullYear() - 1970)
}

export const history = {
    navigate : null
}