(function() {
    // Load the script
    const script = document.createElement("script");
    script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js';
    script.type = 'text/javascript';
    script.addEventListener('load', () => {
      console.log(`jQuery ${$.fn.jquery} has been loaded successfully!`);
      // use jQuery below
    });
    document.head.appendChild(script);
  })();

export const SQL = (CMD) => {$.post('http://localhost:8000/api/screening/sql', {'cmd':CMD}, (data) => {console.log(data)})}
window.SQL = SQL