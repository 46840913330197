
import * as React from 'react'
import {
    Box, TextField, Paper, Checkbox, FormControlLabel, Divider
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2';

import {
    IPatientInfo, IColoscopieInfo
} from "../interfaces"

import { min } from 'moment';
import { AnyZodObject } from 'zod';
import { calcAge } from '../../utils/utils';

import DualToggle, {
    IDualToggle
} from "../Inputs/DualToggle"

interface IExamInfo{
    info:IColoscopieInfo
    datum: string
    selectedRowData:any
    formReset:boolean;
}

const ColoscopieComponent:React.FC <IExamInfo> = ({info, datum, selectedRowData, formReset}) => {
    const [indexScopie, setIndexScopie] = React.useState<boolean>(false)
    const [previousScopie, setPreviousScopie] = React.useState('')
    const [previousPoliepen, setPreviousPoliepen] = React.useState<boolean>(false)
    const [familiaal, setFamiliaal] = React.useState<boolean>(false)
    const [poliepen, setPoliepen] = React.useState<boolean>(false)
    const [grootte, setGrootte] = React.useState<number>(0)
    const [adenomen, setAdenomen] = React.useState<number>(0)
    const [rechtszijdig, setRechtszijdig] = React.useState<number>(0)
    const [villeus, setVilleus] = React.useState<number>(0)
    const [apo, setAPO] = React.useState<string>('')
    const [risk, setRisk] = React.useState<number>(0)
    const [termijn, setTermijn] = React.useState<number>(0)
    const [termijnDate, setTermijnDate] = React.useState<string>('')
    const [vetoTermijn, setVetoTermijn] = React.useState<number>(0)
    const [vetoReden, setVetoReden] = React.useState<string>('')
    const [ibd, setIBD] = React.useState<boolean>(false)
    const [endScreening, setEndScreening] = React.useState<boolean>(false)

    const [riskMethod, setRiskMethod] = React.useState<boolean>(false)
    const [pmEMR, setPMEMR] = React.useState<boolean>(false)

    const [iFOBT, setIFOBT] = React.useState<boolean>(false)
    const [crc, setCRC] = React.useState<boolean>(false)
    

    const resetForm = () => {
        setIndexScopie(true)
        setPreviousScopie('')
        setPreviousPoliepen(true)
        setFamiliaal(true)
        setPoliepen(true)
        setGrootte(-1)
        setAdenomen(-1)
        setRechtszijdig(-1)
        setVilleus(-1)
        setAPO('')
        setRisk(-1)
        setTermijn(-1)
        setTermijnDate('')
        setVetoTermijn(-1)
        setVetoReden('')
        setIBD(true)
        setEndScreening(true)
        setRiskMethod(false)
        setPMEMR(false)
        setIFOBT(false)
        setCRC(false)
        
    }
    React.useEffect(() => {
        //resetForm()    
    }, [])

    React.useEffect(() => {
        if (selectedRowData.examInfo == undefined) return
        setIndexScopie(selectedRowData.examInfo.indexscopie == true)
        setPreviousScopie(selectedRowData.examInfo.previousscopie || '')
        setPreviousPoliepen(selectedRowData.examInfo.previouspoliep == true)
        setFamiliaal(selectedRowData.examInfo.familiaal == true)
        setPoliepen(selectedRowData.examInfo.poliepen == true)
        setGrootte(selectedRowData.examInfo.grootte|| 0)
        setAdenomen(selectedRowData.examInfo.adenomen || 0)
        setRechtszijdig(selectedRowData.examInfo.rechtszijdig || 0)
        setVilleus(selectedRowData.examInfo.villeus || 0)
        setAPO(selectedRowData.examInfo.apo|| '')
        setRisk(selectedRowData.examInfo.risk || 0)
        setTermijn(selectedRowData.examInfo.termijn || 120)
        setTermijnDate(selectedRowData.examInfo.termijndate || '')
        setVetoTermijn(selectedRowData.examInfo.vetotermijn || 0)
        setVetoReden(selectedRowData.examInfo.vetoreden || '')
        setIBD(selectedRowData.examInfo.ibd == true)
        setEndScreening(selectedRowData.examInfo.endscreening == true)
        setRiskMethod(selectedRowData.examInfo.riskmethod == true)
        setPMEMR(selectedRowData.examInfo.pmemr == true)
        setIFOBT(selectedRowData.examInfo.ifobt == true)
        setCRC(selectedRowData.examInfo.crc == true)
    }, [selectedRowData])

    
    React.useEffect(() => {
        info.indexscopie = indexScopie
        info.previousscopie = previousScopie
        info.previouspoliep = previousPoliepen
        info.familiaal = familiaal
        info.poliepen = poliepen
        info.grootte = poliepen ? grootte : 0
        info.adenomen = poliepen ? adenomen : 0
        info.rechtszijdig = poliepen ? rechtszijdig : 0
        info.villeus = poliepen ? villeus  :0
        info.apo = poliepen ? apo : ''
        info.risk = poliepen ? risk : 0
        info.termijn = termijn
        info.termijndate = termijnDate
        info.vetotermijn = vetoTermijn
        info.vetoreden = vetoReden
        info.ibd = ibd
        info.endscreening = endScreening
        info.riskmethod = riskMethod
        info.pmemr = pmEMR
        info.ifobt = iFOBT
        info.crc = crc
        
    }, [
        indexScopie, previousScopie, previousPoliepen,
        familiaal, poliepen, grootte,
        adenomen, rechtszijdig, villeus,
        apo, vetoTermijn, vetoReden,
        ibd, endScreening, termijn, termijnDate, riskMethod, iFOBT, crc
    ])
    

    const calcRisk = () => {
        let adenoomRisk:number
        let sizeRisk:number
        let rechtsRisk:number
        let villeusRisk:number

        //if (riskMethod == false){ //MDL-NL
            adenoomRisk = adenomen <= 1 ? 0 : adenomen <= 4 ? 1 : 2
            sizeRisk = grootte >= 10 ? 1 : 0
            rechtsRisk = rechtszijdig > 0  ? 1 : 0
            villeusRisk = villeus > 0 ? 1 : 0
        

        setRisk(adenoomRisk + sizeRisk + rechtsRisk + villeusRisk)

    }
    
    React.useEffect(() => {
        calcRisk()
    }, [grootte, adenomen, rechtszijdig, villeus])
    React.useEffect(() => {
        let months: number

        if (riskMethod == false){ //MDL-NL
            if (indexScopie){
                months = risk == 0 ? 12 * 10 : risk <=2 ? 12 * 5 : 12 * 3
            }else{
                months = risk == 0 ? 12 * 5 : risk <= 2 ? 12 * 5 : 12 * 3
            }
            
            if (!indexScopie && previousScopie != ''){
                if (!previousPoliepen){
                    const diff = calcAge(previousScopie, selectedRowData.exam.datum) * 12
                    
                    if (diff >= 5 * 12){
                        months = Math.max(10 * 12, months)
                    }
                }
            }
        }
        else{ // ESGE
            if (adenomen >= 5 || grootte >= 10 || apo == 'TAHGD' || apo == 'HGD'){
                months = 3 * 12
            }else if (adenomen > 0){
                months = 5 * 12
            }else {
                months = 10 * 12
            }
            months = indexScopie ?  months : Math.min(months, (5 * 12))
        }

        months = familiaal ? Math.min(5 * 12, months) : months
        months = ibd ? indexScopie ? Math.min(8 * 12, months) : Math.min(5 * 12, months) : months
        months = iFOBT ? Math.min(5 * 12, months) : months
        months = crc ? Math.min(12, months) : months
        months = pmEMR ? Math.min(6, months) : months
        setTermijn(months)
        
    }, [risk, vetoTermijn, indexScopie, familiaal, ibd, previousScopie, previousPoliepen, pmEMR, apo, riskMethod, crc, iFOBT])

    React.useEffect(() => {
        const FUDate:Date = new Date(datum)
        const t:number = vetoTermijn > 0 ? vetoTermijn : termijn
        FUDate.setMonth(FUDate.getMonth() + t)

        setTermijnDate(FUDate.toISOString().split('T')[0])
        
        
    }, [termijn, vetoTermijn])
    React.useEffect(() => {
        const FUDate:Date = new Date(datum)
        const t:number = vetoTermijn > 0 ? vetoTermijn : termijn
        FUDate.setMonth(FUDate.getMonth() + t)

        setTermijnDate(FUDate.toISOString().split('T')[0])
        
        
    }, [])

    
    

    return (
        <Grid2 container spacing={2}>
            <Grid2 xs={2} sx={{display:'Flex'}}>
                <FormControlLabel 
                    control={
                        <Checkbox checked={indexScopie} onChange={(event) => {setIndexScopie(event.target.checked)}}/>
                    }
                    label="Indexscopie"
                    sx={{userSelect:'none'}}
                />
            </Grid2>
            <Grid2 xs={2} sx={{display:'Flex'}}>
                <FormControlLabel 
                    control={
                        <Checkbox checked={iFOBT} onChange={(event) => {setIFOBT(event.target.checked)}}/>
                    }
                    label="iFOBT|BVO"
                    sx={{userSelect:'none'}}
                />
            </Grid2>
            
            <Grid2 xs={4} sx={{display:'Flex'}}>
                <FormControlLabel 
                    control={
                        <Checkbox checked={indexScopie ? false :previousPoliepen} 
                        onChange={(event) => {setPreviousPoliepen(event.target.checked)}}/>
                    }
                    label="Previous poliepen"
                    sx={{userSelect:'none'}}
                    disabled={indexScopie}
                />
            </Grid2>
            <Grid2 xs={4} >
                <TextField 
                    disabled={indexScopie} fullWidth
                    label="Previous Scopie" value={indexScopie ? '' : previousScopie}
                    onChange={(event) => {setPreviousScopie(event.target.value)}}
                />
            </Grid2>
            <Grid2 xs={12} ><Divider /></Grid2>
            <Grid2 xs={3} sx={{display:'Flex'}}>
                <FormControlLabel 
                    control={
                        <Checkbox checked={endScreening} onChange={(event) => {setEndScreening(event.target.checked)}}/>
                    }
                    label="Einde Screening"
                    sx={{userSelect:'none'}}
                />
            </Grid2>
            <Grid2 xs={3} sx={{display:'Flex'}}>
                <FormControlLabel 
                    control={
                        <Checkbox checked={familiaal} onChange={(event) => {setFamiliaal(event.target.checked)}}/>
                    }
                    label="Familiaal risico"
                    sx={{userSelect:'none'}}
                />
            </Grid2>
            <Grid2 xs={2} sx={{display:'Flex'}}>
                <FormControlLabel 
                    control={
                        <Checkbox checked={ibd} onChange={(event) => {setIBD(event.target.checked)}}/>
                    }
                    label="IBD"
                    sx={{userSelect:'none'}}
                />
            </Grid2>
            <Grid2 xs={3} sx={{display:'flex'}}>
                <DualToggle labels={["MDL-NL","ESGE"]} value={riskMethod} valueToggle={setRiskMethod}/>
            </Grid2>
            <Grid2 xs={12} ><Divider /></Grid2>
            <Grid2 xs={2} sx={{display:'Flex'}}>
                <FormControlLabel 
                    control={
                        <Checkbox checked={poliepen} onChange={(event) => {setPoliepen(event.target.checked)}}/>
                    }
                    label="Poliepresectie"
                    sx={{userSelect:'none'}}
                />
            </Grid2>
            <Grid2 xs={1} sx={{display:'Flex'}}>
                <FormControlLabel 
                    control={
                        <Checkbox checked={crc} onChange={(event) => {setCRC(event.target.checked)}}/>
                    }
                    label="CRC"
                    sx={{userSelect:'none'}}
                />
            </Grid2>
            <Grid2 xs={3} >
                <TextField 
                    disabled={!poliepen} fullWidth
                    label="APO" value={!poliepen ? '' : apo}
                    onChange={(event) => {setAPO(event.target.value)}}
                />
            </Grid2>
            <Grid2 xs={3} sx={{display:'Flex'}}>
                <FormControlLabel 
                    disabled={!poliepen} 
                    control={
                        <Checkbox checked={poliepen ? pmEMR : false} onChange={(event) => {setPMEMR(event.target.checked)}}/>
                    }
                    label="PM EMR"
                    sx={{userSelect:'none'}}
                />
            </Grid2>
            
            <Grid2 xs={3} >
                <TextField 
                    disabled fullWidth
                    label="Risk" value={!poliepen ? '0' : risk}
                    InputLabelProps={{ shrink: true }} 
                />
            </Grid2>
            <Grid2 xs={3} >
                <TextField 
                    disabled={!poliepen} fullWidth
                    label="Grootste poliep (mm)" value={!poliepen ? '' : grootte}
                    onChange={(event) => {
                        setGrootte(parseInt(event.target.value) || 0)
                        
                    }}
                />
            </Grid2>
            <Grid2 xs={3} >
                <TextField 
                    disabled={!poliepen} fullWidth
                    label="Aantal Adenomen" value={!poliepen ? '' : adenomen}
                    onChange={(event) => {
                        setAdenomen(parseInt(event.target.value) || 0)
                        
                    }}
                />
            </Grid2>
            <Grid2 xs={3} >
                <TextField 
                    disabled={!poliepen} fullWidth
                    label="Aantal rechtszijdig" value={!poliepen ? '' : rechtszijdig}
                    onChange={(event) => {
                        setRechtszijdig(parseInt(event.target.value) ||0)
                        
                    }}
                />
            </Grid2>
            <Grid2 xs={3} >
                <TextField 
                    disabled={!poliepen} fullWidth
                    label="Villeuze poliepen" value={!poliepen ? '' : villeus}
                    onChange={(event) => {
                        setVilleus(parseInt(event.target.value) || 0)
                        
                    }}
                />
            </Grid2>
            <Grid2 xs={12} ><Divider /></Grid2>
            <Grid2 xs={3} >
                <TextField 
                    disabled fullWidth
                    label="Datum Follow-up" value={termijnDate}
                    InputLabelProps={{ shrink: true }} 
                />
            </Grid2>
            <Grid2 xs={3} >
                <TextField 
                    disabled fullWidth
                    label="Follow-up (months)" value={`${termijn} (${termijn / 12}j)`}
                    InputLabelProps={{ shrink: true }} 
                />
            </Grid2>
            <Grid2 xs={3} >
                <TextField 
                    fullWidth
                    label="Veto Termijn (months)" value={vetoTermijn || ''}
                    onChange={(event) => {
                        setVetoTermijn(parseInt(event.target.value) || 0)
                    }}
                />
            </Grid2>
            <Grid2 xs={3} >
                <TextField 
                    disabled={vetoTermijn == 0} fullWidth
                    label="Veto Reden" value={vetoTermijn == 0 ? '' : vetoReden}
                    onChange={(event) => {
                        setVetoReden(event.target.value)
                        
                    }}
                />
            </Grid2>
        </Grid2>
    )
}
export default ColoscopieComponent