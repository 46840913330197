import * as React from 'react';
import {
    InputLabel, MenuItem, FormControl, 
    Select as MuiSelect, SelectChangeEvent
} from '@mui/material'




export interface SelectProps {
    id: string;
    label: string;
    value: "" | HTMLInputElement | undefined;
    values: string[];
    valueFunc?: Function;
    variant?: "standard" | "outlined" | "filled" | undefined;
    sx?: any;
}

const Select: React.FC<SelectProps> = ({...props}) => {
    const {id, label, value, values, valueFunc, variant, sx} = props

    
    return (
        <FormControl
            variant={variant ? variant : undefined}
            sx={sx ? sx : undefined}
        >
            <InputLabel id={ id + '-label' } > { label } </InputLabel>
            <MuiSelect
                id= { id }
                labelId = { id + '-label' }
                value = { value }
                onChange = {(event:SelectChangeEvent<HTMLInputElement>) => {
                    if (valueFunc){
                        valueFunc(event.target.value)
                    }
                }}
            >
                {
                    values.map(value => {
                        return <MenuItem value={value} key={value}>{value}</MenuItem>
                    })
                }
            </MuiSelect>
        </FormControl>
    )
};

export default Select;
/*
<FormControl variant="standard" sx={{ m: 1, minWidth: 150, float:'right' }}>
                
                <InputLabel id="select-onderzoek-type-label">Onderzoek Type</InputLabel>
                <Select
                    id="onderzoek-select-type"
                    labelId="onderzoek-select-type-label"
                    value={onderzoekSelected}
                    onChange={onChange}

                >
                    {onderzoekTypes.map((item,index) => {
                        return <MenuItem value={item} key={item}>{item}</MenuItem>
                    })}
                </Select>
            </FormControl>
*/