import * as React from 'react'

//import { TabPanel } from '@mui/lab';
import DocCSSPanel from './DocCSSPanel';

import { IDocEditPanel } from '../interfaces/interfaces';

const DocEditPanel : React.FC<IDocEditPanel> = ({value}) => {

    return (
        <DocCSSPanel value={value} >

        </DocCSSPanel>
    )
};

export default DocEditPanel;