import * as React from 'react';
import * as Markup from './Markup'

import { Paper, Typography, Box, Container } from '@mui/material'

import { IDocMarkup } from '../../interfaces/interfaces';

import { styled } from "@mui/material/styles"

const CSSTitle = styled(Typography)({
    color: "var(--red-primary-500);",
    fontSize: "2rem;",
    textShadow: "1px 1px var(--red-primary-1000)",
    fontFamily:'Rubik',
    borderBottom: "3px solid var(--red-primary-900)",
    textAlign:'center', 
    margin:'0 auto',
    marginBlockEnd:'20px',
    width:'fit-content',
    
})

const parseMarkup = (line:string, firebase:any) => {
    switch(line){
        case String(line.match(/^\*img\s.+/i)):
            return Markup.parseIMG(line, firebase)
            break;
        
        default:
            console.log('Not parsed', line)
            return <React.Fragment />
    }
}

const DocMarkup: React.FC<IDocMarkup> = ({selectedItemInfo, firebase}) => {
    const item = selectedItemInfo
    console.log(item)
    const content:string[] = item.content != undefined ? item.content.split('\n') : []
    
    

    return (
        <Box sx={{padding:'5px'}} >
            <Box sx={{margin:'auto', width:'fit-content'}}>
                
                <CSSTitle>{item.label}</CSSTitle>
                {
                    //content == undefined ? <React.Fragment /> : <Box>{content.split('\n').forEach( (v) => {return parseMarkup(v)})}</Box>
                    content.map( (v) => {return parseMarkup(v, firebase)})
                }
                
            </Box>
        </Box>
    )
};

export default DocMarkup;