import * as React from 'react'
import {
    Container
} from '@mui/material'
import Grid from '../../components/Containers/Grid'

import PreviewContainer from './preview'
import InputContainer from './input'

import { ITarificatie } from './interfaces'

const DatabasePage: React.FC = () => {
    const [patient, setPatient] = React.useState<string>('')
    const [datumOnderzoek, setDatumOnderzoek] = React.useState<string>(new Date().toISOString().split('T')[0])

    const props = {
        patient: patient,
        setPatient: setPatient,
        datumOnderzoek: datumOnderzoek,
        setDatumOnderzoek: setDatumOnderzoek,
        
    }

    return (
        <Container  style={{height:'100%', maxWidth:'100vw'}}>
            <Grid sx={{
                gridTemplateColumns: 'auto 1fr', gridTemplateRows: '1fr', gridGap:'10px', 
                height:'calc(100vh - 70px)', fontFamily:'inconsolata', overflow:'hidden',
                padding:'0px 0 0 0'
                }}
            >
                <PreviewContainer {...props}/>
                <InputContainer {...props}/>
            </Grid>
        </Container>
    )
}

export default DatabasePage;